.icon-arrow-down {
  width: 1.56em;
  height: 1em;
  fill: #999FAE;
}
.icon-arrow-next {
  width: 1.45em;
  height: 1em;
  fill: #FFF;
}
.icon-arrow-prev {
  width: 1.45em;
  height: 1em;
  fill: #FFF;
}
.icon-arrow-right {
  width: 0.57em;
  height: 1em;
  fill: #27272E;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: #292930;
}
.icon-star {
  width: 1em;
  height: 1em;
  fill: #5956E9;
}
