@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@300;400;500;600&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
  background: none;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input {
  appearance: none;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon-arrow-down {
  width: 1.56em;
  height: 1em;
  fill: #999FAE;
}

.icon-arrow-next {
  width: 1.45em;
  height: 1em;
  fill: #FFF;
}

.icon-arrow-prev {
  width: 1.45em;
  height: 1em;
  fill: #FFF;
}

.icon-arrow-right {
  width: 0.57em;
  height: 1em;
  fill: #27272E;
}

.icon-search {
  width: 1em;
  height: 1em;
  fill: #292930;
}

.icon-star {
  width: 1em;
  height: 1em;
  fill: #5956E9;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

html.no-scroll {
  overflow: hidden;
}

body {
  min-width: 375px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #292930;
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 15px;
  }
}

body.no-scroll {
  overflow: hidden;
}

.page {
  overflow: hidden;
}

.btn {
  min-width: 178px;
  height: 72px;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -.04em;
  transition: all .25s;
}

.btn__pic, .btn__text {
  display: inline-block;
  vertical-align: middle;
}

.btn__pic {
  max-width: 32px;
}

.btn__pic:not(:last-child) {
  margin-right: 16px;
}

.btn_purple {
  background: #5956E9;
  color: #fff;
}

.btn_pink {
  background: #FAB8C4;
  color: #2522BA;
}

.btn_white {
  background: #fff;
  color: #2522BA;
}

.btn_border {
  border: 1px solid #E3E6E9;
  color: #2522BA;
}

.btn:hover {
  transform: translateY(-2px);
}

.btn:active {
  transform: translateY(2px);
}

.btn[href] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.center {
  max-width: 1184px;
  margin: 0 auto;
  padding: 0 72px;
}

@media only screen and (max-width: 1023px) {
  .center {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .center {
    padding: 0 32px;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 48px 0;
}

@media only screen and (max-width: 1199px) {
  .header {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .header {
    padding: 32px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    padding: 40px 0;
  }
}

.header > .header__center {
  display: flex;
  align-items: center;
}

.header__center {
  max-width: 1440px;
  padding: 0 100px;
}

@media only screen and (max-width: 1365px) {
  .header__center {
    padding: 0 72px;
  }
}

@media only screen and (max-width: 1199px) {
  .header__center {
    padding: 0 36px;
  }
}

.header__logo {
  margin-right: 60px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .header__logo {
    position: relative;
    z-index: 15;
    margin: 0 auto;
  }
}

.header__logo .header__pic {
  width: 145px;
}

@media only screen and (max-width: 767px) {
  .header__logo .header__pic {
    width: 100px;
  }
}

.header__burger {
  position: relative;
  z-index: 15;
  display: none;
  width: 24px;
  height: 24px;
  text-align: center;
  transition: background .3s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 767px) {
  .header__burger {
    display: inline-block;
  }
}

.header__burger:before, .header__burger:after,
.header__burger span {
  display: block;
  height: 2px;
  border-radius: 2px;
  background: #292930;
  transition: transform .3s, width .3s, background .3s;
}

.header__burger:before, .header__burger:after {
  content: '';
}

.header__burger:before,
.header__burger span {
  width: 24px;
}

.header__burger:before {
  margin: 0 auto;
}

.header__burger:after {
  width: 15px;
  margin: 0 auto 0 0;
}

.header__burger span {
  margin: 4px auto;
}

.header__burger.active:before {
  transform: translateY(6px) rotate(45deg);
}

.header__burger.active span {
  transform: rotate(-45deg);
}

.header__burger.active:after {
  width: 24px;
  transform: translateY(-6px) rotate(45deg);
}

.header__wrap {
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .header__wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    min-height: 100vh;
    background: #fff;
    padding: 125px 0 40px;
    transform: translateX(-100%);
    transition: transform .25s;
  }
  .header__wrap.visible {
    transform: translateX(0);
  }
}

.header__nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .header__nav {
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .header__nav {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

.header__photo {
  display: none;
}

@media only screen and (max-width: 767px) {
  .header__photo {
    display: block;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .header__photo .header__pic {
    max-width: 350px;
  }
}

.header__item:not(:last-child) {
  margin-right: 57px;
}

@media only screen and (max-width: 767px) {
  .header__item:not(:last-child) {
    margin: 0 0 24px;
  }
}

.header__item[href], .header__head {
  position: relative;
  padding: 8px 0;
  letter-spacing: -0.04em;
  color: #999FAE;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .header__item[href], .header__head {
    padding: 0;
    font-size: 18px;
    letter-spacing: -0.025em;
  }
}

.header__item[href]:before, .header__head:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  margin: 0 auto;
  background: #5956E9;
  border-radius: 2px;
  transition: width .25s;
}

body:not(.touch-device) .header__item[href]:hover {
  color: #292930;
}

body:not(.touch-device) .header__item[href]:hover:before {
  width: 100%;
}

.header__head {
  display: flex;
  align-items: center;
  height: 78px;
}

@media only screen and (max-width: 767px) {
  .header__head {
    height: auto;
  }
}

.header__head .icon {
  margin-left: 11px;
  font-size: 8px;
  fill: #999FAE;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .header__head .icon {
    display: none;
  }
}

.header__head:before {
  right: 24px;
  bottom: 19px;
}

body:not(.touch-device) .header__item:hover .header__head {
  color: #292930;
}

body:not(.touch-device) .header__item:hover .header__head .icon {
  fill: #5956E9;
}

body:not(.touch-device) .header__item:hover .header__head:before {
  width: calc(100% - 24px);
}

.header__item.active .header__head {
  color: #292930;
}

.header__item.active .header__head .icon {
  fill: #5956E9;
}

.header__item.active .header__head:before {
  width: calc(100% - 24px);
}

.header__body {
  position: absolute;
  top: calc(100% - 48px);
  left: 0;
  right: 0;
  padding-top: 23px;
  transform: translateY(20px);
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .header__body {
    display: none;
  }
}

body:not(.touch-device) .header__item:hover .header__body {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.header__item.active .header__body {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.header__row {
  display: flex;
  padding: 65px 0;
  background: #fff;
  border-radius: 32px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1365px) {
  .header__row {
    padding: 55px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .header__row {
    padding: 40px 0;
  }
}

.header__col {
  padding-left: 100px;
  padding-right: 40px;
}

@media only screen and (max-width: 1365px) {
  .header__col {
    padding-left: 62px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__col {
    padding: 0 20px 0 30px;
  }
}

.header__col:first-child {
  flex: 0 0 36%;
}

.header__col:first-child .header__box:first-child .header__preview {
  top: -7px;
  left: -18px;
  width: 75px;
}

.header__col:first-child .header__box:nth-child(2) .header__preview {
  top: 0;
  left: -8px;
  width: 105px;
}

.header__col:first-child .header__box:nth-child(3) .header__preview {
  top: -2px;
  left: -12px;
  width: 90px;
}

.header__col:nth-child(2) {
  flex: 0 0 30%;
}

.header__col:nth-child(2) .header__box:first-child .header__preview {
  top: 0;
  left: -32px;
  width: 125px;
}

.header__col:nth-child(2) .header__box:nth-child(2) .header__preview {
  top: -10px;
  left: -8px;
  width: 110px;
}

.header__col:nth-child(3) {
  flex: 0 0 34%;
}

.header__col:nth-child(3) .header__box:first-child .header__preview {
  top: 2px;
  left: -24px;
  width: 85px;
}

.header__col:nth-child(3) .header__box:nth-child(2) .header__preview {
  top: 0px;
  left: -3px;
  width: 70px;
}

.header__col:not(:last-child) {
  border-right: 1px solid rgba(153, 159, 174, 0.2);
}

.header__category {
  margin-bottom: 35px;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
}

.header__box {
  display: block;
  position: relative;
  padding-left: 56px;
  font-weight: 500;
}

.header__box:not(:last-child) {
  margin-bottom: 35px;
}

.header__preview {
  position: absolute;
  font-size: 0;
}

.header__preview .header__pic {
  width: 100%;
}

.header__info {
  margin-bottom: 2px;
  font-size: 15px;
  color: #292930;
  transition: color .25s;
}

.header__text {
  font-size: 13px;
  color: #999FAE;
  transition: color .25s;
}

.header__box:hover .header__info {
  color: #5956E9;
}

.header__box:hover .header__text {
  color: rgba(153, 159, 174, 0.4);
}

.header__search {
  position: relative;
  margin-right: 40px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .header__search {
    margin-right: 60px;
  }
}

@media only screen and (max-width: 1023px) {
  .header__search {
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__search {
    z-index: 16;
  }
}

.header__open {
  position: relative;
  z-index: 2;
  width: 24px;
  height: 24px;
  font-size: 0;
  transition: opacity .25s;
}

.header__open .icon {
  font-size: 20px;
  fill: #fff;
  transition: fill .25s;
}

@media only screen and (max-width: 1365px) {
  .header__open .icon {
    fill: #292930;
  }
}

.header__open:hover {
  opacity: .8;
}

.header__search.active .header__open .icon {
  fill: #5956E9;
}

.header__field {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -5px;
  width: 250px;
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

@media only screen and (max-width: 1365px) {
  .header__field {
    width: 230px;
  }
}

@media only screen and (max-width: 767px) {
  .header__field {
    width: 320px;
  }
}

.header__search.active .header__field {
  visibility: visible;
  opacity: 1;
}

.header__input {
  width: 100%;
  height: 34px;
  padding: 0 35px 0 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(41, 41, 48, 0.1);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #292930;
}

.header__input::placeholder {
  color: #999FAE;
}

.header__btn.btn {
  min-width: 180px;
}

@media only screen and (max-width: 1023px) {
  .header__btn.btn {
    display: none;
  }
}

.header__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
}

@media only screen and (max-width: 1023px) {
  .header__bg.show {
    visibility: visible;
    opacity: 1;
  }
}

.title {
  position: relative;
  font-family: 'DM Sans', sans-serif;
  font-size: 80px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -.025em;
}

@media only screen and (max-width: 1199px) {
  .title {
    font-size: 68px;
  }
}

@media only screen and (max-width: 767px) {
  .title {
    font-size: 48px;
  }
}

.title__line {
  position: relative;
}

.title__line:before {
  content: "";
  position: absolute;
  left: calc(100% + 10px);
  bottom: 30px;
  width: 72px;
  height: 8px;
  background: #5956E9;
}

@media only screen and (max-width: 767px) {
  .title__line:before {
    left: calc(100% + 7px);
    bottom: 19px;
    width: 46px;
    height: 5px;
  }
}

.title__box {
  display: block;
  overflow: hidden;
}

.title__text {
  display: block;
  opacity: 0;
  transform: translateY(110%);
  transition: transform .4s, opacity .4s;
}

.title__color {
  color: #5956E9;
}

.title_sm {
  font-size: 40px;
  letter-spacing: -.045em;
}

@media only screen and (max-width: 1199px) {
  .title_sm {
    font-size: 32px;
    line-height: 1.1;
  }
}

@media only screen and (max-width: 767px) {
  .title_sm {
    font-size: 32px;
  }
}

.title_big {
  font-size: 96px;
}

@media only screen and (max-width: 1199px) {
  .title_big {
    font-size: 96px;
  }
}

@media only screen and (max-width: 767px) {
  .title_big {
    font-size: 60px;
  }
}

.title[data-aos] .title__box:nth-child(2) .title__text {
  transition-delay: .2s;
}

.title[data-aos] .title__box:nth-child(3) .title__text {
  transition-delay: .4s;
}

.title[data-aos] .title__box:nth-child(4) .title__text {
  transition-delay: .6s;
}

.title[data-aos].aos-animate .title__text {
  transform: translateY(0);
  opacity: 1;
}

.info {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1.5;
  color: #999FAE;
}

@media only screen and (max-width: 767px) {
  .info {
    font-size: 16px;
    line-height: 1.5;
  }
}

.stage {
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .stage {
    font-size: 16px;
  }
}

.main {
  position: relative;
  z-index: 2;
  padding: 220px 0 100px;
}

@media only screen and (max-width: 767px) {
  .main {
    padding: 170px 0 35px;
  }
}

.main__wrap {
  max-width: 440px;
}

@media only screen and (max-width: 767px) {
  .main__wrap {
    max-width: 100%;
  }
}

.main__title {
  margin-bottom: 21px;
}

@media only screen and (max-width: 767px) {
  .main__title {
    max-width: 400px;
    margin-bottom: 40px;
  }
  .main__title br {
    display: none;
  }
}

@media only screen and (max-width: 666px) {
  .main__title {
    max-width: 300px;
  }
  .main__title br {
    display: block;
  }
}

.main__info {
  max-width: 320px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .main__info {
    max-width: 100%;
    margin-bottom: 48px;
  }
}

@media only screen and (max-width: 666px) {
  .main__info {
    max-width: 230px;
  }
}

.main__control {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .main__control {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 666px) {
  .main__control {
    margin-bottom: 113px;
  }
}

.main__control .main__icon {
  margin-top: -3px;
}

.main__btn {
  height: 82px;
}

@media only screen and (max-width: 767px) {
  .main__btn {
    height: 64px;
  }
}

.main__btn:not(:last-child) {
  margin-right: 32px;
}

@media only screen and (max-width: 767px) {
  .main__btn:not(:last-child) {
    margin-right: 16px;
  }
}

.main__link {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #999FAE;
  transition: opacity .25s;
}

.main__link:hover {
  opacity: .8;
}

@media only screen and (max-width: 767px) {
  .main__link:hover {
    opacity: 1;
  }
}

.main__icon {
  margin-right: 16px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .main__control .main__link {
    justify-content: center;
    width: 64px;
    height: 64px;
    border: 1px solid #E3E6E9;
    border-radius: 16px;
    font-size: 0;
  }
}

@media only screen and (max-width: 767px) {
  .main__control .main__icon {
    margin: 0;
  }
}

.main__view {
  position: absolute;
  top: 206px;
  right: calc(50% - 686px);
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .main__view {
    right: calc(50% - 700px);
  }
}

@media only screen and (max-width: 767px) {
  .main__view {
    position: relative;
    top: auto;
    right: auto;
  }
}

.main__preview:first-child {
  top: 13px;
  right: -33px;
  width: 370px;
}

@media only screen and (max-width: 767px) {
  .main__preview:first-child {
    top: 60px;
    right: calc(50% - 200px);
    width: 110px;
  }
}

.main__preview:nth-child(2) {
  left: -2px;
  bottom: 165px;
  width: 235px;
}

@media only screen and (max-width: 767px) {
  .main__preview:nth-child(2) {
    left: calc(50% - 225px);
    bottom: 95px;
    width: 130px;
  }
}

.main__preview:nth-child(3) {
  right: 157px;
  bottom: 10px;
  width: 98px;
}

@media only screen and (max-width: 767px) {
  .main__preview:nth-child(3) {
    right: calc(50% - 135px);
    bottom: 6px;
    width: 45px;
  }
}

.main__preview:nth-child(4) {
  width: 768px;
}

@media only screen and (max-width: 1023px) {
  .main__preview:nth-child(4) {
    width: 700px;
  }
}

@media only screen and (max-width: 767px) {
  .main__preview:nth-child(4) {
    width: auto;
    margin: 0 -90px 0 -42px;
    text-align: center;
  }
  .main__preview:nth-child(4) .main__pic {
    max-width: 420px;
  }
}

.main__preview:not(:last-child) {
  position: absolute;
}

.main__view[data-aos] .main__preview {
  opacity: 0;
  transition: all .5s;
}

.main__view[data-aos] .main__preview:first-child {
  transform: translate(40px, -40px);
}

.main__view[data-aos] .main__preview:nth-child(2) {
  transform: translateX(-40px) skewX(10deg);
}

.main__view[data-aos] .main__preview:nth-child(3) {
  transform: translateX(20px);
}

.main__view[data-aos] .main__preview:nth-child(4) {
  transform: translateY(50px);
}

.main__view[data-aos] .main__preview:not(:last-child) {
  z-index: 2;
}

.main__view[data-aos].aos-animate .main__preview {
  opacity: 1;
}

.main__view[data-aos].aos-animate .main__preview:first-child {
  transition-delay: .3s;
  transform: translate(0, 0);
}

.main__view[data-aos].aos-animate .main__preview:nth-child(2) {
  transition-delay: .45s;
  transform: translateX(0) skewX(0);
}

.main__view[data-aos].aos-animate .main__preview:nth-child(3) {
  transition-delay: .15s;
  transform: translateX(0);
}

.main__view[data-aos].aos-animate .main__preview:nth-child(4) {
  transform: translateY(0);
}

.main__view .main__pic {
  width: 100%;
}

.main__circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.main__circle {
  position: absolute;
  border-radius: 50%;
}

.main__circle:first-child {
  top: -200px;
  left: -200px;
  width: 400px;
  height: 400px;
  background: #FFDC60;
}

@media only screen and (max-width: 1365px) {
  .main__circle:first-child {
    top: -134px;
    left: -134px;
    width: 268px;
    height: 268px;
  }
}

@media only screen and (max-width: 767px) {
  .main__circle:first-child {
    display: none;
  }
}

.main__circle:nth-child(2) {
  top: 44%;
  left: calc(50% - 620px);
  width: 48px;
  height: 48px;
  background: #FAB8C4;
}

.main__circle:nth-child(3) {
  left: 46%;
  top: 229px;
  z-index: 2;
  width: 128px;
  height: 128px;
  background: #FFDC60;
}

@media only screen and (max-width: 1023px) {
  .main__circle:nth-child(3) {
    left: 52%;
  }
}

@media only screen and (max-width: 767px) {
  .main__circle:nth-child(3) {
    top: 309px;
    left: 62%;
    width: 32px;
    height: 32px;
  }
}

.main__circle:nth-child(4) {
  left: 49.5%;
  bottom: 253px;
  width: 32px;
  height: 32px;
  background: #5956E9;
}

@media only screen and (max-width: 767px) {
  .main__circle:nth-child(4) {
    top: 445px;
    left: auto;
    right: 40px;
    width: 24px;
    height: 24px;
  }
}

.main__circle:nth-child(5) {
  right: calc(50% - 250px);
  bottom: -90px;
  z-index: 2;
  width: 180px;
  height: 180px;
  background: #FAB8C4;
}

@media only screen and (max-width: 1199px) {
  .main__circle:nth-child(5) {
    right: calc(50% - 250px);
    bottom: -70px;
    z-index: 2;
    width: 140px;
    height: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .main__circle:nth-child(5) {
    right: -90px;
    top: 740px;
    width: 180px;
    height: 180px;
  }
}

.main__circle:nth-child(6) {
  top: -98px;
  right: calc(50% - 1330px);
  width: 1220px;
  height: 1220px;
  background: #5956E9;
}

@media only screen and (max-width: 1365px) {
  .main__circle:nth-child(6) {
    right: calc(50% - 1480px);
  }
}

@media only screen and (max-width: 1199px) {
  .main__circle:nth-child(6) {
    top: -47px;
    right: calc(50% - 1252px);
    width: 1118px;
    height: 1118px;
  }
}

@media only screen and (max-width: 1023px) {
  .main__circle:nth-child(6) {
    right: calc(50% - 1300px);
  }
}

@media only screen and (max-width: 1023px) {
  .main__circle:nth-child(6) {
    top: 116px;
    right: -85px;
    width: 170px;
    height: 170px;
  }
}

.main__circle:nth-child(7) {
  top: 57%;
  right: calc(50% - 594px);
  z-index: 2;
  width: 103px;
  height: 103px;
  background: #fff;
}

.features {
  position: relative;
  padding: 150px 0 100px;
  overflow: hidden;
  background: #27272E;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .features {
    padding: 80px 0;
  }
}

.features__center {
  position: relative;
  z-index: 2;
}

.features__head {
  display: flex;
  margin-bottom: 192px;
}

@media only screen and (max-width: 1199px) {
  .features__head {
    margin-bottom: 212px;
  }
}

@media only screen and (max-width: 1023px) {
  .features__head {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .features__head {
    display: block;
    margin-bottom: 135px;
  }
}

.features__stage {
  flex-shrink: 0;
  width: 268px;
  padding-top: 7px;
}

@media only screen and (max-width: 1199px) {
  .features__stage {
    width: 216px;
  }
}

@media only screen and (max-width: 767px) {
  .features__stage {
    width: 100%;
    margin-bottom: 16px;
  }
}

.features__title {
  margin-bottom: 48px;
}

@media only screen and (max-width: 1199px) {
  .features__title {
    margin-bottom: 32px;
  }
}

.features__info {
  max-width: 505px;
  color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 767px) {
  .features__info {
    max-width: 100%;
  }
}

@media only screen and (max-width: 666px) {
  .features__info {
    max-width: 250px;
  }
}

.features__body {
  position: relative;
  padding-left: 268px;
}

@media only screen and (max-width: 1199px) {
  .features__body {
    padding-left: 216px;
  }
}

@media only screen and (max-width: 767px) {
  .features__body {
    padding: 0;
  }
}

.features__body:before {
  content: "";
  position: absolute;
  top: -85px;
  left: calc(50% + 200px);
  width: 1000px;
  height: 1px;
  background: #FFBABA;
}

@media only screen and (max-width: 1199px) {
  .features__body:before {
    left: calc(50% + 160px);
  }
}

@media only screen and (max-width: 1023px) {
  .features__body:before {
    display: none;
  }
}

.features__container {
  margin: 0 -65px;
}

@media only screen and (max-width: 1199px) {
  .features__container {
    margin: 0 -55px;
  }
}

@media only screen and (max-width: 767px) {
  .features__container {
    margin: 0 -10px;
  }
}

.features__slider {
  visibility: hidden;
}

.features__slider.slick-initialized {
  visibility: visible;
}

.features__slide {
  padding: 0 65px;
}

@media only screen and (max-width: 1199px) {
  .features__slide {
    padding: 0 55px;
  }
}

@media only screen and (max-width: 767px) {
  .features__slide {
    padding: 0 10px;
  }
}

.features__slide:first-child .features__preview, .features__slide:nth-child(3) .features__preview, .features__slide:nth-child(5) .features__preview {
  top: 10px;
  left: -8px;
  width: 180px;
}

.features__slide:nth-child(2) .features__preview, .features__slide:nth-child(4) .features__preview {
  top: 0;
  left: -20px;
  width: 160px;
}

.features__details {
  position: relative;
  padding-top: 115px;
}

.features__preview {
  position: absolute;
  font-size: 0;
}

.features__pic {
  width: 100%;
}

.features__category {
  margin-bottom: 35px;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -.04em;
}

@media only screen and (max-width: 1199px) {
  .features__category {
    margin-bottom: 24px;
    font-size: 20px;
  }
}

.features__text {
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 1199px) {
  .features__text {
    margin-bottom: 32px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 666px) {
  .features__text {
    max-width: 250px;
  }
}

.features__link {
  position: relative;
  display: inline-block;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .features__link {
    font-size: 16px;
  }
}

.features__link:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 1px;
  background: #fff;
  transition: width .25s;
}

.features__link:hover {
  padding-left: 50px;
  color: #fff;
}

.features__link:hover:before {
  width: 34px;
}

.features__status {
  position: absolute;
  left: 0;
  bottom: 186px;
  display: flex;
  justify-content: space-between;
  width: 135px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .features__status {
    bottom: 169px;
  }
}

@media only screen and (max-width: 1023px) {
  .features__status {
    bottom: 126px;
  }
}

@media only screen and (max-width: 767px) {
  .features__status {
    top: -80px;
    left: auto;
    right: 0;
    bottom: auto;
    width: 115px;
  }
}

.features__status:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 1px;
  background: #fff;
}

.features__bg {
  position: absolute;
  top: 0;
  left: calc(50% - 1142px);
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .features__bg {
    left: calc(50% - 1042px);
    width: 1100px;
  }
}

@media only screen and (max-width: 767px) {
  .features__bg {
    top: -30px;
    left: auto;
    right: -245px;
    width: 915px;
  }
}

.features__bg[data-aos] {
  transform: rotate(30deg);
}

.features__bg[data-aos].aos-animate {
  transform: rotate(0);
}

.features__circle {
  position: absolute;
  top: 115px;
  right: calc(50% - 520px);
  width: 268px;
  height: 268px;
  border-radius: 50%;
  background: #525260;
}

@media only screen and (max-width: 1199px) {
  .features__circle {
    right: calc(50% - 590px);
  }
}

@media only screen and (max-width: 767px) {
  .features__circle {
    top: 37px;
    right: -200px;
  }
}

.features .status__all {
  color: rgba(255, 255, 255, 0.3);
}

.features .slick-arrow {
  position: absolute;
  bottom: 6px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 0;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .features .slick-arrow {
    top: -88px;
    bottom: auto;
  }
}

.features .slick-arrow .icon {
  font-size: 12px;
  fill: #fff;
}

.features .slick-arrow:hover {
  background: #5956E9;
  border-color: #5956E9;
}

.features .slick-prev {
  left: -203px;
}

@media only screen and (max-width: 1199px) {
  .features .slick-prev {
    left: -161px;
  }
}

@media only screen and (max-width: 767px) {
  .features .slick-prev {
    left: 10px;
  }
}

.features .slick-next {
  left: -146px;
}

@media only screen and (max-width: 1199px) {
  .features .slick-next {
    left: -104px;
  }
}

@media only screen and (max-width: 767px) {
  .features .slick-next {
    left: 67px;
  }
}

.access {
  position: relative;
  padding: 236px 0 0;
  background: #ECF2F6;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .access {
    padding: 180px 0 80px;
  }
}

.access__view {
  position: relative;
  z-index: 4;
  display: inline-block;
  margin-right: -164px;
  margin-bottom: 137px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .access__view {
    margin: 0 -60px 10px 55px;
    text-align: center;
  }
}

.access__view[data-aos] .access__preview {
  opacity: 0;
  transition: all .5s;
}

.access__view[data-aos] .access__preview:first-child {
  transform: translateY(40px);
}

.access__view[data-aos] .access__preview:nth-child(2) {
  transform: translate(40px, -40px);
}

.access__view[data-aos] .access__preview:nth-child(3) {
  transform: translateX(-40px) skewX(10deg);
}

.access__view[data-aos] .access__preview:not(:first-child) {
  z-index: 2;
}

.access__view[data-aos].aos-animate .access__preview {
  opacity: 1;
}

.access__view[data-aos].aos-animate .access__preview:first-child {
  transform: translateY(0);
}

.access__view[data-aos].aos-animate .access__preview:nth-child(2) {
  transition-delay: .2s;
  transform: translate(0, 0);
}

.access__view[data-aos].aos-animate .access__preview:nth-child(3) {
  transition-delay: .4s;
  transform: translateX(0) skewX(0);
}

.access__preview:first-child {
  width: 487px;
}

@media only screen and (max-width: 767px) {
  .access__preview:first-child {
    width: 100%;
    max-width: 420px;
  }
}

.access__preview:nth-child(2) {
  top: -57px;
  right: -44px;
  width: 300px;
}

@media only screen and (max-width: 767px) {
  .access__preview:nth-child(2) {
    top: -35px;
    right: -20px;
    width: 190px;
  }
}

.access__preview:nth-child(3) {
  top: 178px;
  left: -98px;
  width: 213px;
}

@media only screen and (max-width: 767px) {
  .access__preview:nth-child(3) {
    top: 60px;
    left: -85px;
    width: 170px;
  }
}

.access__preview:not(:first-child) {
  position: absolute;
}

.access__pic {
  width: 100%;
}

.access__circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.access__circle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}

.access__circle:first-child {
  top: 204px;
  z-index: 3;
  width: 475px;
  height: 475px;
  background: #5956E9;
}

@media only screen and (max-width: 767px) {
  .access__circle:first-child {
    top: 172px;
    width: 286px;
    height: 286px;
  }
}

.access__circle:nth-child(2) {
  top: 140px;
  z-index: 2;
  width: 603px;
  height: 603px;
  background: rgba(255, 255, 255, 0.3);
}

@media only screen and (max-width: 767px) {
  .access__circle:nth-child(2) {
    top: 160px;
    width: 311px;
    height: 311px;
  }
}

.access__circle:nth-child(3) {
  top: 80px;
  width: 723px;
  height: 723px;
  background: rgba(255, 255, 255, 0.3);
}

@media only screen and (max-width: 767px) {
  .access__circle:nth-child(3) {
    display: none;
  }
}

.access__circle[data-aos] {
  transform: translateX(-50%) scale(0.5);
  opacity: 0;
  transition-property: transform, opacity;
}

.access__circle[data-aos].aos-animate {
  transform: translateX(-50%) scale(1);
  opacity: 1;
}

.access__cloud {
  position: absolute;
  z-index: 3;
}

.access__cloud:first-child {
  top: 230px;
  left: calc(50% - 422px);
  width: 175px;
}

@media only screen and (max-width: 767px) {
  .access__cloud:first-child {
    top: 80px;
    left: -50px;
    width: 107px;
  }
}

.access__cloud:nth-child(2) {
  top: 523px;
  right: calc(50% - 412px);
  width: 114px;
}

@media only screen and (max-width: 767px) {
  .access__cloud:nth-child(2) {
    top: 375px;
    right: -23px;
    width: 56px;
  }
}

.access__balls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.access__ball {
  position: absolute;
  border-radius: 50%;
}

.access__ball:first-child, .access__ball:nth-child(2), .access__ball:nth-child(3), .access__ball:nth-child(4) {
  background: #fff;
}

.access__ball:first-child {
  top: 260px;
  left: calc(50% - 619px);
  width: 16px;
  height: 16px;
}

@media only screen and (max-width: 767px) {
  .access__ball:first-child {
    top: 125px;
    left: 110px;
    width: 10px;
    height: 10px;
  }
}

.access__ball:nth-child(2) {
  left: calc(50% - 411px);
  bottom: 284px;
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 767px) {
  .access__ball:nth-child(2) {
    display: none;
  }
}

.access__ball:nth-child(3) {
  top: 124px;
  left: calc(50% - 365px);
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 767px) {
  .access__ball:nth-child(3) {
    top: 360px;
    left: 35px;
    width: 10px;
    height: 10px;
  }
}

.access__ball:nth-child(4) {
  right: calc(50% - 560px);
  bottom: 384px;
  width: 16px;
  height: 16px;
}

@media only screen and (max-width: 767px) {
  .access__ball:nth-child(4) {
    top: 333px;
    right: 5px;
    width: 5px;
    height: 5px;
  }
}

.access__ball:nth-child(5) {
  left: calc(50% - 560px);
  bottom: 35%;
  width: 90px;
  height: 90px;
  background: #FFDC60;
}

@media only screen and (max-width: 767px) {
  .access__ball:nth-child(5) {
    top: 413px;
    left: -15px;
    width: 30px;
    height: 30px;
  }
}

.access__ball:nth-child(6) {
  top: 211px;
  right: calc(50% - 476px);
  width: 48px;
  height: 48px;
  background: #5956E9;
}

@media only screen and (max-width: 767px) {
  .access__ball:nth-child(6) {
    top: 82px;
    right: 49px;
    width: 24px;
    height: 24px;
  }
}

.access__ball:nth-child(7) {
  right: calc(50% - 758px);
  bottom: 55.5%;
  width: 80px;
  height: 80px;
  background: #FAB8C4;
}

@media only screen and (max-width: 767px) {
  .access__ball:nth-child(7) {
    display: none;
  }
}

.access__wrap {
  position: relative;
  z-index: 5;
  max-width: 504px;
  margin: 0 auto;
}

.access__info {
  margin-bottom: 42px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -.045em;
}

@media only screen and (max-width: 767px) {
  .access__info {
    margin-bottom: 32px;
    font-size: 20px;
  }
}

.access__form {
  position: relative;
  margin-bottom: -50px;
}

@media only screen and (max-width: 767px) {
  .access__form {
    margin: 0;
  }
}

.access__field {
  position: relative;
}

.access__input {
  width: 100%;
  height: 96px;
  padding: 0 210px 0 72px;
  background: #fff;
  border-radius: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  letter-spacing: -.025em;
  color: #292930;
}

@media only screen and (max-width: 767px) {
  .access__input {
    height: 72px;
    padding: 0 30px 0 72px;
  }
}

.access__input::placeholder {
  color: #999FAE;
}

.access__btn {
  position: absolute;
  top: 50%;
  margin-top: -41px;
  right: 12px;
  height: 82px;
}

@media only screen and (max-width: 767px) {
  .access__btn {
    position: static;
    min-width: 100%;
    height: 72px;
    margin-top: 16px;
  }
}

.access__icon {
  position: absolute;
  top: 50%;
  left: 32px;
  transform: translateY(-50%);
  width: 24px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .access__icon {
    left: 24px;
  }
}

.layouts {
  position: relative;
  padding: 231px 0 165px;
  background: #fff;
}

@media only screen and (max-width: 1199px) {
  .layouts {
    padding: 216px 0 126px;
  }
}

@media only screen and (max-width: 1023px) {
  .layouts {
    padding: 150px 0 125px;
  }
}

@media only screen and (max-width: 767px) {
  .layouts {
    padding: 80px 0 25px;
  }
}

.layouts__wrap {
  max-width: 450px;
}

@media only screen and (max-width: 767px) {
  .layouts__wrap {
    max-width: 100%;
    margin-bottom: 90px;
  }
}

.layouts__stage {
  margin-bottom: 24px;
  color: #5956E9;
}

@media only screen and (max-width: 767px) {
  .layouts__stage {
    margin-bottom: 18px;
  }
}

.layouts__head {
  margin-bottom: 50px;
}

@media only screen and (max-width: 1199px) {
  .layouts__head {
    margin-bottom: 32px;
  }
}

.layouts__info {
  max-width: 400px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1199px) {
  .layouts__info {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .layouts__info {
    margin-bottom: 48px;
  }
}

.layouts__item {
  display: flex;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.1;
}

.layouts__item:not(:last-child) {
  margin-bottom: 31px;
}

.layouts__icon {
  flex-shrink: 0;
  width: 56px;
  margin-right: 24px;
  font-size: 0;
}

.layouts__pic {
  width: 100%;
}

.layouts__view {
  position: absolute;
  top: 287px;
  right: calc(50% - 717px);
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .layouts__view {
    right: calc(50% - 670px);
  }
}

@media only screen and (max-width: 1023px) {
  .layouts__view {
    top: 210px;
    right: calc(50% - 600px);
  }
}

@media only screen and (max-width: 767px) {
  .layouts__view {
    position: relative;
    top: auto;
    right: auto;
    margin: 0 -155px 0 -130px;
    text-align: center;
  }
}

.layouts__view[data-aos] .layouts__preview {
  opacity: 0;
  transition: all .5s;
}

.layouts__view[data-aos] .layouts__preview:first-child {
  transform: translateY(40px);
}

.layouts__view[data-aos] .layouts__preview:nth-child(2) {
  transform: translate(40px, -40px);
}

.layouts__view[data-aos] .layouts__preview:nth-child(3) {
  transform: translateX(40px);
}

.layouts__view[data-aos] .layouts__preview:nth-child(4) {
  transform: translateX(-40px) skewX(10deg);
}

.layouts__view[data-aos] .layouts__preview:nth-child(5) {
  transform: translate(40px, 40px);
}

.layouts__view[data-aos] .layouts__preview:not(:first-child) {
  z-index: 2;
}

.layouts__view[data-aos].aos-animate .layouts__preview {
  opacity: 1;
}

.layouts__view[data-aos].aos-animate .layouts__preview:first-child {
  transform: translateY(0);
}

.layouts__view[data-aos].aos-animate .layouts__preview:nth-child(2) {
  transition-delay: .2s;
  transform: translate(0, 0);
}

.layouts__view[data-aos].aos-animate .layouts__preview:nth-child(3) {
  transition-delay: .3s;
  transform: translateX(0);
}

.layouts__view[data-aos].aos-animate .layouts__preview:nth-child(4) {
  transition-delay: .4s;
  transform: translateX(0) skewX(0);
}

.layouts__view[data-aos].aos-animate .layouts__preview:nth-child(5) {
  transition-delay: .5s;
  transform: translate(0, 0);
}

.layouts__preview:first-child {
  width: 786px;
}

@media only screen and (max-width: 1199px) {
  .layouts__preview:first-child {
    width: 730px;
  }
}

@media only screen and (max-width: 767px) {
  .layouts__preview:first-child {
    width: auto;
    max-width: 600px;
    margin: 0 auto;
  }
}

.layouts__preview:nth-child(2) {
  top: -76px;
  right: 133px;
  width: 250px;
}

@media only screen and (max-width: 1199px) {
  .layouts__preview:nth-child(2) {
    top: -80px;
    width: 210px;
  }
}

@media only screen and (max-width: 1023px) {
  .layouts__preview:nth-child(2) {
    right: 150px;
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .layouts__preview:nth-child(2) {
    top: 40px;
    right: calc(50% - 170px);
    width: 120px;
  }
}

.layouts__preview:nth-child(3) {
  right: 198px;
  bottom: 114px;
  width: 51px;
}

@media only screen and (max-width: 1199px) {
  .layouts__preview:nth-child(3) {
    display: none;
  }
}

.layouts__preview:nth-child(4) {
  left: 111px;
  bottom: 149px;
  width: 218px;
}

@media only screen and (max-width: 1199px) {
  .layouts__preview:nth-child(4) {
    width: 200px;
  }
}

@media only screen and (max-width: 1023px) {
  .layouts__preview:nth-child(4) {
    left: 140px;
    width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .layouts__preview:nth-child(4) {
    left: calc(50% - 180px);
    bottom: 125px;
    width: 120px;
  }
}

.layouts__preview:nth-child(5) {
  top: -155px;
  left: 60px;
  width: 426px;
}

@media only screen and (max-width: 1199px) {
  .layouts__preview:nth-child(5) {
    width: 400px;
  }
}

@media only screen and (max-width: 1023px) {
  .layouts__preview:nth-child(5) {
    top: -100px;
    left: 160px;
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .layouts__preview:nth-child(5) {
    top: -66px;
    left: calc(50% - 197px);
    width: 234px;
  }
}

.layouts__preview:not(:first-child) {
  position: absolute;
}

.like {
  position: relative;
  margin-bottom: 125px;
}

@media only screen and (max-width: 1199px) {
  .like {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .like {
    margin-bottom: 80px;
  }
}

.like__center {
  max-width: 1384px;
}

@media only screen and (max-width: 1365px) {
  .like__center {
    padding: 0 34px;
  }
}

@media only screen and (max-width: 767px) {
  .like__center {
    max-width: 400px;
    padding: 0 16px;
  }
}

.like__container {
  position: relative;
  max-width: 1240px;
  margin: 0 auto;
  padding: 105px 100px;
  border-radius: 40px;
  border: 1px solid #F1F5F8;
  background: #fff linear-gradient(90deg, #ECF2F6 0%, rgba(236, 242, 246, 0) 70.31%);
}

@media only screen and (max-width: 1365px) {
  .like__container {
    padding: 80px 38px;
  }
}

@media only screen and (max-width: 1199px) {
  .like__container {
    padding: 105px 38px 143px;
  }
}

@media only screen and (max-width: 767px) {
  .like__container {
    padding: 375px 16px 80px;
    text-align: center;
    overflow: hidden;
  }
}

.like__container:before, .like__container:after {
  content: "";
  position: absolute;
  left: 16px;
  right: 16px;
  border-radius: 40px;
  background: rgba(236, 242, 246, 0.5);
}

@media only screen and (max-width: 767px) {
  .like__container:before, .like__container:after {
    left: 8px;
    right: 8px;
  }
}

.like__container:before {
  top: 8px;
  bottom: -8px;
  z-index: -1;
}

.like__container:after {
  top: 16px;
  bottom: -16px;
  z-index: -2;
}

.like__preview {
  position: absolute;
  right: 0;
  bottom: 35px;
  z-index: 3;
  width: 520px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .like__preview {
    right: -130px;
  }
}

@media only screen and (max-width: 1023px) {
  .like__preview {
    right: -230px;
    width: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .like__preview {
    top: 40px;
    right: -85px;
    width: 330px;
    bottom: auto;
  }
}

.like__preview[data-aos] {
  transform: translateX(100px) rotate(30deg);
  opacity: 0;
  transition-property: transform, opacity;
}

.like__preview[data-aos].aos-animate {
  transform: translateX(-40px) scaleX(1.1);
  opacity: 1;
}

.like__circle {
  position: absolute;
  border-radius: 50%;
}

.like__container > .like__circle {
  top: 50%;
  right: calc(50% - 430px);
  z-index: 2;
  width: 410px;
  height: 410px;
  margin-top: -205px;
  background: #FAB8C4;
}

@media only screen and (max-width: 1023px) {
  .like__container > .like__circle {
    top: 60%;
    width: 360px;
    height: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .like__container > .like__circle {
    top: 70px;
    width: 252px;
    height: 252px;
    right: 50%;
    margin: 0 -126px 0 0;
  }
}

.like__pic {
  width: 100%;
}

.like__wrap {
  position: relative;
  z-index: 4;
  max-width: 430px;
}

@media only screen and (max-width: 767px) {
  .like__wrap {
    max-width: 100%;
  }
}

.like__title {
  margin-bottom: 40px;
}

@media only screen and (max-width: 1199px) {
  .like__title {
    margin-bottom: 32px;
  }
}

.like__text {
  max-width: 390px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1199px) {
  .like__text {
    margin-bottom: 50px;
    color: #6D7381;
  }
}

@media only screen and (max-width: 767px) {
  .like__text {
    margin-bottom: 32px;
  }
}

.like__text p:not(:last-child) {
  margin-bottom: 23px;
}

.like__btn .btn {
  min-width: 240px;
}

@media only screen and (max-width: 767px) {
  .like__btn .btn {
    min-width: 253px;
  }
}

.like__circles .like__circle {
  z-index: 1;
}

.like__circles .like__circle:first-child {
  left: 240px;
  bottom: -73px;
  width: 130px;
  height: 130px;
  background: #FFDC60;
}

@media only screen and (max-width: 767px) {
  .like__circles .like__circle:first-child {
    top: 62px;
    right: 32px;
    left: auto;
    bottom: auto;
    width: 26px;
    height: 26px;
  }
}

.like__circles .like__circle:nth-child(2) {
  top: 128px;
  left: 44.5%;
  width: 70px;
  height: 70px;
  background: #FAB8C4;
}

@media only screen and (max-width: 1199px) {
  .like__circles .like__circle:nth-child(2) {
    top: 54px;
    width: 48px;
    height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .like__circles .like__circle:nth-child(2) {
    top: 65px;
    left: 24px;
    width: 13px;
    height: 13px;
  }
}

.like__circles .like__circle:nth-child(3) {
  top: -45px;
  right: 150px;
  width: 90px;
  height: 90px;
  background: #5956E9;
}

@media only screen and (max-width: 1199px) {
  .like__circles .like__circle:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .like__circles .like__circle:nth-child(3) {
    display: block;
    top: 101px;
    left: 55px;
    right: auto;
    width: 35px;
    height: 35px;
    z-index: 2;
  }
}

.like__circles .like__circle:nth-child(4) {
  top: 174px;
  right: 26px;
  width: 24px;
  height: 24px;
  background: #FAB8C4;
}

@media only screen and (max-width: 1199px) {
  .like__circles .like__circle:nth-child(4) {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .like__circles .like__circle:nth-child(4) {
    display: block;
    top: 340px;
    right: 82px;
    width: 18px;
    height: 18px;
  }
}

.switch {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
  transition: opacity .25s;
}

.switch__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch__flex {
  display: inline-flex;
  justify-content: center;
}

.switch__box {
  position: relative;
  display: block;
  width: 56px;
  height: 24px;
  margin: 0 24px;
  padding: 4px 5px;
  background: #5956E9;
  border-radius: 12px;
  transition: all .25s;
}

.switch__box:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  transition: all .25s;
}

.switch:hover {
  opacity: .85;
}

.switch__yearly, .switch__monthly {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -.04em;
  color: #99A1AA;
  transition: color .25s;
}

.switch__yearly {
  color: #5956E9;
}

.switch__input:checked + .switch__flex .switch__box:before {
  transform: translate(30px, -50%);
}

.switch__input:checked + .switch__flex .switch__yearly {
  color: #99A1AA;
}

.switch__input:checked + .switch__flex .switch__monthly {
  color: #5956E9;
}

.plan {
  position: relative;
  margin-bottom: 110px;
}

@media only screen and (max-width: 1199px) {
  .plan {
    margin-bottom: 95px;
  }
}

@media only screen and (max-width: 767px) {
  .plan {
    margin-bottom: 40px;
  }
}

.plan__head {
  margin-bottom: 72px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .plan__head {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .plan__head {
    margin-bottom: 32px;
  }
}

.plan__stage {
  margin-bottom: 16px;
  color: #292930;
}

.plan__head .plan__title {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .plan__head .plan__title {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .plan__head .plan__title {
    margin-bottom: 32px;
  }
}

.plan__info {
  max-width: 380px;
  margin: 0 auto 34px;
}

@media only screen and (max-width: 767px) {
  .plan__info {
    margin-bottom: 30px;
  }
}

.plan .switch {
  margin-left: -12px;
}

@media only screen and (max-width: 767px) {
  .plan .switch {
    margin-left: 10px;
  }
}

.plan__variants {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .plan__variants {
    display: block;
    margin-top: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .plan__variants {
    margin-top: 14px;
  }
}

.plan__select {
  min-width: 400px;
  height: 60px;
  padding: 0 20px 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0 0 2px;
  border-style: solid;
  border-color: #E3E6E9;
  border-radius: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' fill='none'%3E%3Cpath fill-rule='evenodd' d='M5.707.293a1 1 0 0 0-1.414 0l-4 4a1 1 0 1 0 1.414 1.414L5 2.414l3.293 3.293a1 1 0 1 0 1.414-1.414l-4-4zM.293 11.207l4 4a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L5 13.086 1.707 9.793a1 1 0 0 0-1.414 0 1 1 0 0 0 0 1.414z' fill='%23292930'/%3E%3C/svg%3E") no-repeat 100% 50%/10px auto;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #5956E9;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .plan__select {
    border-width: 0 0 1px;
  }
}

@media only screen and (max-width: 666px) {
  .plan__select {
    min-width: 100%;
    font-size: 16px;
  }
}

.plan__select::-ms-expand {
  display: none;
}

.plan__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.plan__body {
  display: flex;
  margin: 0 -20px;
}

@media only screen and (max-width: 1199px) {
  .plan__body {
    margin: 0 -12px;
  }
}

@media only screen and (max-width: 1023px) {
  .plan__body {
    display: block;
    max-width: 400px;
    margin: 0 auto;
  }
}

.plan__item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(33.333% - 40px);
  width: calc(33.333% - 40px);
  margin: 0 20px;
  padding: 40px;
  border-radius: 32px;
}

@media only screen and (max-width: 1199px) {
  .plan__item {
    flex: 0 0 calc(33.333% - 24px);
    width: calc(33.333% - 24px);
    margin: 0 12px;
    padding: 24px 24px 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .plan__item {
    width: 100%;
    margin: 0;
    padding: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .plan__item {
    padding: 24px;
  }
}

.plan__item:first-child {
  background: #5956E9;
  color: #fff;
}

.plan__item:first-child .plan__price {
  color: rgba(255, 255, 255, 0.5);
}

.plan__item:first-child .plan__money {
  color: #fff;
}

.plan__item:first-child .plan__list li {
  color: #fff;
}

.plan__item:first-child .plan__list li:before {
  background-image: url("../img/list-icon-1.svg");
}

.plan__item:nth-child(2) {
  background: #fff;
  box-shadow: 0 20px 40px rgba(41, 41, 48, 0.05);
}

.plan__item:nth-child(3) {
  border: 1px solid #E3E6E9;
}

.plan__item:not(:first-child) {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .plan__item:not(:first-child) {
    display: block !important;
  }
}

.plan__star {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #FFDC60;
}

@media only screen and (max-width: 1199px) {
  .plan__star {
    top: 12px;
    right: 12px;
    width: 32px;
    height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .plan__star {
    top: 20px;
    right: 20px;
  }
}

.plan__star .icon {
  font-size: 16px;
  fill: #5956E9;
}

@media only screen and (max-width: 1199px) {
  .plan__star .icon {
    font-size: 11px;
  }
}

.plan__category {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-size: 14px;
  letter-spacing: 0;
}

.plan__icon {
  flex-shrink: 0;
  margin-right: 14px;
  font-size: 0;
}

.plan__item .plan__title {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .plan__item .plan__title {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .plan__item .plan__title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .plan__item .plan__title {
    font-size: 32px;
  }
}

.plan__price {
  margin-bottom: 45px;
  font-size: 14px;
  color: #999FAE;
}

@media only screen and (max-width: 1199px) {
  .plan__price {
    margin-bottom: 32px;
  }
}

.plan__money {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  color: #5956E9;
}

.plan__list {
  flex-grow: 1;
  margin-bottom: 40px;
}

.plan__list li {
  position: relative;
  padding-left: 36px;
  color: #999FAE;
}

.plan__list li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background: url("../img/list-icon-2.svg") no-repeat 0 0/100% auto;
}

.plan__list li:not(:last-child) {
  margin-bottom: 24px;
}

.plan__btn {
  min-width: 100%;
  margin-top: auto;
}

.plan__btn.btn_pink {
  color: #292930;
}

.plan__circle {
  position: absolute;
  top: 170px;
  right: calc(50% - 414px);
  width: 49px;
  height: 49px;
  z-index: -1;
  border-radius: 50%;
  background: #FAB8C4;
}

.work {
  position: relative;
  height: 923px;
  margin-bottom: 239px;
  padding: 148px 0 0;
  background: #FAB8C4;
}

@media only screen and (max-width: 1199px) {
  .work {
    margin-bottom: 190px;
  }
}

@media only screen and (max-width: 1023px) {
  .work {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .work {
    height: 897px;
    margin-bottom: 295px;
    padding-top: 80px;
  }
}

.work__head {
  position: relative;
  z-index: 4;
  margin-bottom: 117px;
}

.work__stage {
  margin-bottom: 16px;
  color: #C75C6F;
}

.work__title {
  font-size: 144px;
  font-weight: 700;
  color: #fff;
}

@media only screen and (max-width: 1199px) {
  .work__title {
    font-size: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .work__title {
    font-size: 78px;
  }
}

.work__title .title__color {
  color: #3A38BF;
}

.work__details {
  position: absolute;
  right: calc(50% - 520px);
  bottom: -170px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 505px;
  height: 505px;
  margin-left: auto;
  padding: 0 90px 35px 95px;
  border-radius: 50%;
  background: #5956E9;
}

@media only screen and (max-width: 1023px) {
  .work__details {
    right: -50px;
    width: 450px;
    height: 450px;
    padding: 0 55px;
  }
}

@media only screen and (max-width: 767px) {
  .work__details {
    right: 50%;
    bottom: -196px;
    display: inline-block;
    width: 505px;
    height: 505px;
    margin-right: -252px;
    padding: 48px 90px 0;
    text-align: center;
  }
}

.work__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
  border-radius: 12px;
  background: #fff;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .work__icon {
    width: 107px;
    height: 107px;
    margin-bottom: 40px;
    border-radius: 50%;
  }
}

.work__text {
  margin-bottom: 24px;
  font-size: 22px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .work__text {
    margin-bottom: 32px;
    font-size: 18px;
  }
}

.work__link {
  position: relative;
  display: inline-block;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .work__link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 196px;
    height: 82px;
    border-radius: 20px;
    background: #FAB8C4;
    font-size: 16px;
    color: #292930;
  }
}

.work__link:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 1px;
  background: #fff;
  transition: width .25s;
}

@media only screen and (max-width: 767px) {
  .work__link:before {
    display: none;
  }
}

.work__link:hover {
  padding-left: 50px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .work__link:hover {
    padding: 0;
    color: #292930;
  }
}

.work__link:hover:before {
  width: 34px;
}

.work__pic {
  width: 100%;
}

.work__icon .work__pic {
  width: 25px;
}

.work__circle {
  position: absolute;
  z-index: 2;
  border-radius: 50%;
}

.work__circle:first-child {
  top: 140px;
  left: calc(50% - 640px);
  width: 40px;
  height: 40px;
  background: #fff;
}

@media only screen and (max-width: 767px) {
  .work__circle:first-child {
    top: 110px;
    left: auto;
    right: 110px;
    width: 23px;
    height: 23px;
  }
}

.work__circle:nth-child(2) {
  left: calc(50% - 431px);
  bottom: 133px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 767px) {
  .work__circle:nth-child(2) {
    top: 570px;
    left: 98px;
    bottom: auto;
    width: 14px;
    height: 14px;
  }
}

.work__circle:nth-child(3) {
  left: calc(50% - 322px);
  bottom: 221px;
  width: 95px;
  height: 95px;
  background: #fff;
}

@media only screen and (max-width: 1023px) {
  .work__circle:nth-child(3) {
    left: -30px;
    bottom: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .work__circle:nth-child(3) {
    display: none;
  }
}

.work__circle:nth-child(4) {
  left: calc(50% - 109px);
  bottom: 77px;
  width: 64px;
  height: 64px;
  background: #fff;
}

@media only screen and (max-width: 1023px) {
  .work__circle:nth-child(4) {
    left: calc(50% - 150px);
  }
}

@media only screen and (max-width: 767px) {
  .work__circle:nth-child(4) {
    display: none;
  }
}

.work__circle:nth-child(5) {
  top: 41.3%;
  left: calc(50% - 179px);
  width: 204px;
  height: 204px;
  background: #5956E9;
}

@media only screen and (max-width: 767px) {
  .work__circle:nth-child(5) {
    top: 494px;
    left: auto;
    right: 32px;
    width: 32px;
    height: 32px;
  }
}

.work__wave, .work__preview, .work__hand, .work__cursor {
  position: absolute;
  pointer-events: none;
  font-size: 0;
}

.work__wave {
  left: 0;
  right: 0;
  bottom: -127px;
  z-index: 1;
}

@media only screen and (max-width: 1439px) {
  .work__wave {
    bottom: -57px;
  }
}

@media only screen and (max-width: 1199px) {
  .work__wave {
    left: -150px;
    right: -220px;
  }
}

@media only screen and (max-width: 474px) {
  .work__wave {
    left: -50px;
    bottom: 200px;
  }
}

.work__preview {
  top: 37px;
  right: calc(50% - 604px);
  z-index: 3;
  width: 897px;
}

@media only screen and (max-width: 1199px) {
  .work__preview {
    right: calc(50% - 610px);
    top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .work__preview {
    right: calc(50% - 390px);
    width: 800px;
  }
}

.work__preview[data-aos] {
  opacity: 0;
  transform: translate(50px, -50px) rotate(0);
  transition-property: transform, opacity;
}

.work__preview[data-aos].aos-animate {
  transform: translate(0, 0) rotate(0);
  opacity: 1;
}

.work__hand {
  z-index: 5;
}

.work__hand:first-child {
  left: calc(50% - 827px);
  bottom: -421px;
  width: 662px;
}

@media only screen and (min-width: 1600px) {
  .work__hand:first-child {
    left: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .work__hand:first-child {
    left: calc(50% - 600px);
    bottom: -161px;
    width: 500px;
  }
}

@media only screen and (max-width: 1023px) {
  .work__hand:first-child {
    left: calc(50% - 500px);
    bottom: -200px;
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .work__hand:first-child {
    left: 0;
    bottom: 60px;
    width: 330px;
  }
}

@media only screen and (max-width: 666px) {
  .work__hand:first-child {
    left: calc(50% - 260px);
  }
}

.work__hand:first-child[data-aos] {
  transform: translate(-100px, 100px) rotate(-30deg);
}

.work__hand:nth-child(2) {
  right: calc(50% - 813px);
  bottom: -600px;
  width: 524px;
}

@media only screen and (min-width: 1600px) {
  .work__hand:nth-child(2) {
    right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .work__hand:nth-child(2) {
    right: calc(50% - 600px);
    bottom: -340px;
    width: 370px;
  }
}

@media only screen and (max-width: 1023px) {
  .work__hand:nth-child(2) {
    right: calc(50% - 500px);
    bottom: -280px;
    width: 330px;
  }
}

@media only screen and (max-width: 767px) {
  .work__hand:nth-child(2) {
    display: none;
  }
}

.work__hand:nth-child(2)[data-aos] {
  transform: translate(100px, 100px) rotate(30deg);
}

.work__hand[data-aos] {
  opacity: 0;
  transition-property: transform, opacity;
}

.work__hand[data-aos].aos-animate {
  transform: translate(0, 0) rotate(0);
  opacity: 1;
}

.work__cursor {
  top: 55%;
  left: calc(50% - 89px);
  z-index: 5;
  width: 135px;
}

@media only screen and (max-width: 1023px) {
  .work__cursor {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .work__cursor {
    display: block;
    top: auto;
    left: 50%;
    bottom: -250px;
    width: 100px;
  }
}

.work__cursor[data-aos] {
  opacity: 0;
  transform: translate(50px, 50px) rotate(0);
  transition-property: transform, opacity;
}

.work__cursor[data-aos].aos-animate {
  transform: translate(0, 0) rotate(0);
  opacity: 1;
}

.teams {
  position: relative;
  margin-bottom: 138px;
}

@media only screen and (max-width: 1199px) {
  .teams {
    margin-bottom: 170px;
  }
}

@media only screen and (max-width: 767px) {
  .teams {
    margin-bottom: 80px;
  }
}

.teams__head {
  margin-bottom: 143px;
}

@media only screen and (max-width: 1199px) {
  .teams__head {
    margin-bottom: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .teams__head {
    margin-bottom: 112px;
  }
}

.teams__stage {
  margin-bottom: 24px;
  color: #C75C6F;
}

.teams__body {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .teams__body {
    display: block;
    margin-right: -32px;
  }
}

.teams__col:first-child {
  flex-shrink: 0;
  width: 360px;
}

@media only screen and (max-width: 1023px) {
  .teams__col:first-child {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .teams__col:first-child {
    display: none;
  }
}

.teams__col:nth-child(2) {
  flex: 0 0 calc(100% - 360px);
  width: calc(100% - 360px);
}

@media only screen and (max-width: 1023px) {
  .teams__col:nth-child(2) {
    flex: 0 0 calc(100% - 300px);
    width: calc(100% - 300px);
  }
}

@media only screen and (max-width: 767px) {
  .teams__col:nth-child(2) {
    width: 100%;
  }
}

.teams__body .teams__title {
  max-width: 230px;
}

@media only screen and (max-width: 1199px) {
  .teams__body .teams__title {
    max-width: 375px;
    font-size: 40px;
  }
}

.teams__container {
  margin: 0 -20px;
}

@media only screen and (max-width: 767px) {
  .teams__container {
    margin: 0 -8px;
  }
}

.teams__slider {
  visibility: hidden;
}

.teams__slider.slick-initialized {
  visibility: visible;
}

.teams__slide {
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .teams__slide {
    padding: 0 8px;
    opacity: .3;
    transition: opacity .25s;
  }
  .teams__slide.slick-active {
    opacity: 1;
  }
}

.teams__line {
  height: 2px;
  margin-bottom: 60px;
}

@media only screen and (max-width: 1199px) {
  .teams__line {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .teams__line {
    width: 148px;
    margin-bottom: 32px;
  }
}

.teams__name {
  margin-bottom: 27px;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

@media only screen and (max-width: 1199px) {
  .teams__name {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .teams__name {
    margin-bottom: 21px;
  }
}

.teams__text {
  max-width: 290px;
  margin-bottom: 40px;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  letter-spacing: -.025em;
  line-height: 1.25;
  color: #292930;
}

@media only screen and (max-width: 1199px) {
  .teams__text {
    max-width: 100%;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .teams__text {
    font-size: 18px;
  }
  .teams__text br {
    display: none;
  }
}

.teams__user {
  display: flex;
  align-items: center;
}

.teams__ava {
  flex-shrink: 0;
  width: 72px;
  font-size: 0;
}

.teams__ava .teams__pic {
  max-height: 51px;
}

.teams__details {
  font-weight: 500;
}

.teams__man {
  font-weight: 500;
  letter-spacing: -.04em;
}

.teams__login {
  font-size: 14px;
  color: #999FAE;
}

.teams__circle {
  position: absolute;
  top: 0;
  right: calc(50% - 638px);
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: #FAB8C4;
}

@media only screen and (max-width: 767px) {
  .teams__circle {
    top: -90px;
    left: 27px;
    right: auto;
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .teams .slick-list {
    padding-right: 100px;
  }
}

.teams .slick-arrow {
  position: absolute;
  top: 118px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #FAB8C4;
  font-size: 0;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .teams .slick-arrow {
    top: -80px;
  }
}

.teams .slick-arrow .icon {
  font-size: 12px;
  fill: #fff;
}

.teams .slick-arrow:hover {
  background: #5956E9;
}

.teams .slick-prev {
  left: -340px;
}

@media only screen and (max-width: 1023px) {
  .teams .slick-prev {
    left: -280px;
  }
}

@media only screen and (max-width: 767px) {
  .teams .slick-prev {
    left: 8px;
  }
}

.teams .slick-next {
  left: -283px;
}

@media only screen and (max-width: 1023px) {
  .teams .slick-next {
    left: -223px;
  }
}

@media only screen and (max-width: 767px) {
  .teams .slick-next {
    left: 65px;
  }
}

.speed {
  position: relative;
  margin-bottom: 302px;
}

@media only screen and (max-width: 1199px) {
  .speed {
    margin-bottom: 320px;
  }
}

@media only screen and (max-width: 1023px) {
  .speed {
    margin-bottom: 210px;
  }
}

@media only screen and (max-width: 767px) {
  .speed {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .speed__wrap {
    margin-bottom: 32px;
  }
}

.speed__head {
  margin-bottom: 80px;
}

@media only screen and (max-width: 1199px) {
  .speed__head {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .speed__head {
    margin-bottom: 32px;
  }
}

.speed__stage {
  margin-bottom: 24px;
  color: #A4B16E;
}

@media only screen and (max-width: 767px) {
  .speed__stage {
    margin-bottom: 16px;
  }
}

.speed__title {
  margin-bottom: 56px;
}

@media only screen and (max-width: 1199px) {
  .speed__title {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .speed__title br {
    display: none;
  }
}

@media only screen and (max-width: 666px) {
  .speed__title br {
    display: block;
  }
}

.speed__text {
  max-width: 320px;
}

@media only screen and (max-width: 1199px) {
  .speed__text {
    max-width: 275px;
  }
}

.speed__number {
  font-family: 'DM Sans', sans-serif;
  font-size: 80px;
  line-height: 1;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .speed__number {
    margin-left: -10px;
  }
}

.speed__number span {
  margin-right: 30px;
  font-family: 'Poppins', sans-serif;
  font-size: 288px;
  letter-spacing: -.05em;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .speed__number span {
    font-size: 275px;
  }
}

.speed__line, .speed__preview, .speed__circle, .speed__plant {
  position: absolute;
  font-size: 0;
  pointer-events: none;
}

.speed__pic {
  width: 100%;
}

.speed__line {
  top: 182px;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .speed__line {
    top: auto;
    left: -300px;
    right: -300px;
    bottom: 300px;
  }
}

.speed__preview {
  top: -25px;
  right: calc(50% - 429px);
  width: 408px;
}

@media only screen and (max-width: 1199px) {
  .speed__preview {
    top: -90px;
  }
}

@media only screen and (max-width: 1023px) {
  .speed__preview {
    top: -20px;
    right: calc(50% - 400px);
    width: 330px;
  }
}

@media only screen and (max-width: 767px) {
  .speed__preview {
    position: relative;
    top: auto;
    right: auto;
    width: 310px;
    margin: 0 auto;
  }
}

.speed__preview:before {
  content: "";
  position: absolute;
  top: 112px;
  left: 50%;
  z-index: -1;
  width: 592px;
  height: 592px;
  margin-left: -294px;
  border-radius: 50%;
  background: #5956E9;
}

@media only screen and (max-width: 1199px) {
  .speed__preview:before {
    top: 110px;
  }
}

@media only screen and (max-width: 1023px) {
  .speed__preview:before {
    top: 77px;
    width: 500px;
    height: 500px;
    margin-left: -250px;
  }
}

@media only screen and (max-width: 767px) {
  .speed__preview:before {
    top: 84px;
    width: 452px;
    height: 452px;
    margin-left: -226px;
  }
}

.speed__preview[data-aos]:before {
  transform: scale(0.5);
  opacity: 1;
  transition: all .4s;
}

.speed__preview[data-aos] .speed__graphic {
  transform: translateY(-100px);
  opacity: 0;
  transition: all .5s;
  transition-delay: .4s;
}

.speed__preview[data-aos] .speed__plant {
  transform: translateX(60px) skewX(-30deg);
  opacity: 0;
  transition: all .3s;
  transition-delay: .6s;
}

.speed__preview[data-aos].aos-animate:before {
  transform: scale(1);
  opacity: 1;
}

.speed__preview[data-aos].aos-animate .speed__graphic {
  transform: translateY(0);
  opacity: 1;
}

.speed__preview[data-aos].aos-animate .speed__plant {
  transform: translateX(0) skewX(0);
  opacity: 1;
}

.speed__plant {
  left: 330px;
  bottom: 135px;
  width: 380px;
}

@media only screen and (max-width: 1199px) {
  .speed__plant {
    width: 300px;
    left: 255px;
  }
}

@media only screen and (max-width: 1023px) {
  .speed__plant {
    display: none;
  }
}

.speed__circle {
  z-index: -1;
  border-radius: 50%;
}

.speed__circle:first-child {
  top: 240px;
  left: calc(50% - 627px);
  width: 32px;
  height: 32px;
  background: #5956E9;
}

@media only screen and (max-width: 767px) {
  .speed__circle:first-child {
    top: 172px;
    right: -16px;
    left: auto;
  }
}

.speed__circle:nth-child(2) {
  top: 112px;
  left: calc(50% - 436px);
  width: 120px;
  height: 120px;
  background: #FFDC60;
}

@media only screen and (max-width: 1199px) {
  .speed__circle:nth-child(2) {
    left: calc(50% - 360px);
  }
}

@media only screen and (max-width: 767px) {
  .speed__circle:nth-child(2) {
    top: 48px;
    left: 115px;
  }
}

@media only screen and (max-width: 767px) {
  .speed__circle:nth-child(3) {
    top: 530px;
    left: -32px;
    width: 64px;
    height: 64px;
    background: #FAB8C4;
  }
}

.package {
  position: relative;
  margin-bottom: 178px;
}

@media only screen and (max-width: 1199px) {
  .package {
    margin-bottom: 165px;
  }
}

@media only screen and (max-width: 1023px) {
  .package {
    margin-bottom: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .package {
    margin-bottom: 80px;
    overflow: hidden;
  }
}

.package__center {
  max-width: 1384px;
}

@media only screen and (max-width: 1365px) {
  .package__center {
    padding: 0 36px;
  }
}

@media only screen and (max-width: 767px) {
  .package__center {
    padding: 0;
  }
}

.package__container {
  position: relative;
  max-width: 1240px;
  margin: 0 auto;
}

.package__container:before, .package__container:after {
  content: "";
  position: absolute;
  left: 16px;
  right: 16px;
  border-radius: 40px;
}

@media only screen and (max-width: 767px) {
  .package__container:before, .package__container:after {
    display: none;
  }
}

.package__container:before {
  top: 8px;
  bottom: -8px;
  z-index: -1;
  background: rgba(250, 184, 196, 0.3);
}

.package__container:after {
  top: 16px;
  bottom: -16px;
  z-index: -2;
  background: rgba(250, 184, 196, 0.5);
}

.package__wrap {
  position: relative;
  padding: 100px 45px 32px 100px;
  overflow: hidden;
  background: #FFD3DB;
  border-radius: 40px;
}

@media only screen and (max-width: 1365px) {
  .package__wrap {
    padding: 60px 45px 32px 38px;
  }
}

@media only screen and (max-width: 1199px) {
  .package__wrap {
    padding: 36px 0 36px 38px;
  }
}

@media only screen and (max-width: 1023px) {
  .package__wrap {
    padding: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .package__wrap {
    padding: 16px;
    border-radius: 0;
  }
}

.package__circle {
  position: absolute;
  border-radius: 50%;
}

.package__wrap .package__circle {
  top: -98px;
  left: -98px;
  width: 506px;
  height: 506px;
  background: #5956E9;
}

@media only screen and (max-width: 767px) {
  .package__wrap .package__circle {
    display: none;
  }
}

.package__wrap .package__circle[data-aos] {
  transform: translate(-100%, -100%);
  transition-property: transform;
}

.package__wrap .package__circle[data-aos].aos-animate {
  transform: translate(0, 0);
}

.package__slider {
  position: relative;
  z-index: 2;
  visibility: hidden;
}

@media only screen and (max-width: 1199px) {
  .package__slider {
    margin-right: -130px;
  }
}

@media only screen and (max-width: 1023px) {
  .package__slider {
    margin-right: 0;
  }
}

.package__slider.slick-initialized {
  visibility: visible;
}

.package__row {
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .package__row {
    align-items: center;
  }
}

@media only screen and (max-width: 1023px) {
  .package__row {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .package__row {
    padding-bottom: 50px;
    background: #fff;
    border-radius: 24px;
  }
}

.package__details {
  flex-shrink: 0;
  width: 410px;
  margin-right: 74px;
  padding: 50px 48px 31px;
  border-radius: 16px;
  background: #fff;
}

@media only screen and (max-width: 1199px) {
  .package__details {
    margin-right: 58px;
  }
}

@media only screen and (max-width: 1023px) {
  .package__details {
    width: 500px;
    margin: 0 auto 20px;
  }
}

@media only screen and (max-width: 767px) {
  .package__details {
    width: 100%;
    margin: 0 0 20px;
    padding: 50px 32px 0;
    background: none;
  }
}

.package__details[data-aos] {
  transform: translateX(-100%);
  opacity: 0;
  transition-property: transform, opacity;
}

.package__details[data-aos].aos-animate {
  transform: translateX(0);
  opacity: 1;
}

.package__category {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.package__category .package__icon {
  flex-shrink: 0;
  width: 24px;
  margin-right: 14px;
  font-size: 0;
}

.package__pic {
  width: 100%;
}

.package__title {
  margin-bottom: 36px;
}

@media only screen and (max-width: 767px) {
  .package__title {
    margin-bottom: 24px;
  }
}

.package__info {
  max-width: 250px;
  margin-bottom: 50px;
  color: #999FAE;
}

@media only screen and (max-width: 767px) {
  .package__info {
    margin-bottom: 32px;
  }
}

.package__list {
  margin-bottom: 54px;
}

@media only screen and (max-width: 767px) {
  .package__list {
    margin-bottom: 24px;
  }
}

.package__link {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 30px;
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #292930;
  transition: opacity .25s;
}

.package__link:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 10px;
  height: 18px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='18' fill='none'%3E%3Cpath fill-rule='evenodd' d='M.293.293a1 1 0 0 1 1.414 0l8 8a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414L7.586 9 .293 1.707a1 1 0 0 1 0-1.414z' fill='%23000'/%3E%3C/svg%3E") no-repeat 50% 50%/cover;
}

.package__link:hover {
  opacity: .85;
}

.package__link:not(:last-child) {
  margin-bottom: 32px;
}

.package__link .package__icon {
  flex-shrink: 0;
  width: 56px;
  margin-right: 24px;
  font-size: 0;
}

.package__btns {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .package__btn {
    min-width: 148px;
    height: 64px;
  }
}

.package__arrows {
  display: flex;
  margin-right: auto;
}

.package__arrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #FAB8C4;
  font-size: 0;
  transition: all .25s;
}

.package__arrow .icon {
  font-size: 12px;
  fill: #fff;
}

.package__arrow:hover {
  background: #5956E9;
}

.package__arrow:not(:last-child) {
  margin-right: 17px;
}

.package__line {
  position: absolute;
  top: -92px;
  left: -100px;
  right: -100px;
  z-index: 1;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .package__line {
    top: -50px;
  }
}

.package__preview {
  padding-top: 75px;
  text-align: center;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .package__preview {
    padding-top: 0;
  }
}

.package__preview .package__pic {
  width: auto;
  max-width: 100%;
  max-height: 400px;
}

.package__circles .package__circle:first-child {
  bottom: -70px;
  left: calc(50% - 212px);
  width: 205px;
  height: 205px;
  background: #5956E9;
}

@media only screen and (max-width: 1199px) {
  .package__circles .package__circle:first-child {
    left: calc(50% - 132px);
  }
}

@media only screen and (max-width: 1023px) {
  .package__circles .package__circle:first-child {
    left: auto;
    right: -100px;
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .package__circles .package__circle:first-child {
    right: -60px;
    bottom: -60px;
  }
}

.package__circles .package__circle:first-child:before {
  content: "";
  position: absolute;
  top: 32px;
  left: 32px;
  right: 32px;
  bottom: 32px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.14);
}

@media only screen and (max-width: 767px) {
  .package__circles .package__circle:first-child:before {
    top: 24px;
    right: 24px;
    left: 24px;
    bottom: 24px;
  }
}

.package__circles .package__circle:nth-child(2) {
  top: 90px;
  right: calc(50% - 247px);
  width: 48px;
  height: 48px;
  background: #fff;
}

@media only screen and (max-width: 1199px) {
  .package__circles .package__circle:nth-child(2) {
    right: calc(50% - 400px);
  }
}

.package .slick-dots {
  position: absolute;
  left: 480px;
  right: 0;
  bottom: 62px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .package .slick-dots {
    left: 336px;
    bottom: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .package .slick-dots {
    left: 0;
    bottom: -20px;
  }
}

.package .slick-dots li:not(:last-child) {
  margin-right: 24px;
}

.package .slick-dots li button {
  position: relative;
  width: 12px;
  height: 12px;
  font-size: 0;
}

.package .slick-dots li button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  font-size: 0;
  transition: all .25s;
}

.package .slick-dots li.slick-active button:before {
  width: 12px;
  height: 12px;
  border: 3px solid #fff;
  background: #5956E9;
}

.demo {
  position: relative;
  margin-bottom: 276px;
}

@media only screen and (max-width: 1199px) {
  .demo {
    margin-bottom: 210px;
  }
}

@media only screen and (max-width: 1023px) {
  .demo {
    margin-bottom: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .demo {
    margin-bottom: 100px;
  }
}

.demo__center {
  max-width: 1182px;
}

.demo__head {
  margin-bottom: 73px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .demo__head {
    margin-bottom: 58px;
  }
}

.demo__icon {
  margin-bottom: 22px;
  font-family: 'DM Sans', sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 1.29167;
}

.demo__info {
  max-width: 450px;
  margin: 0 auto;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -.045em;
}

@media only screen and (max-width: 767px) {
  .demo__info {
    max-width: 260px;
    font-size: 20px;
  }
}

.demo__list {
  display: flex;
  margin: 0 -20px;
}

@media only screen and (max-width: 1023px) {
  .demo__list {
    margin: 0 -12px;
  }
}

@media only screen and (max-width: 767px) {
  .demo__list {
    display: block;
    margin: 0;
  }
}

.demo__item {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(50% - 40px);
  width: calc(50% - 40px);
  margin: 0 20px;
  padding: 80px 80px 70px;
  border-radius: 40px;
}

@media only screen and (max-width: 1199px) {
  .demo__item {
    padding: 80px 50px;
  }
}

@media only screen and (max-width: 1023px) {
  .demo__item {
    flex: 0 0 calc(50% - 24px);
    width: calc(50% - 24px);
    margin: 0 12px;
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .demo__item {
    width: 100%;
    margin: 0;
    padding: 32px;
  }
}

.demo__item:first-child {
  border: 1px solid #E3E6E9;
  background: #fff;
}

.demo__item:nth-child(2) {
  background: #5956E9;
  color: #fff;
}

.demo__item:nth-child(2) .demo__text {
  color: #fff;
}

.demo__item:nth-child(2) .demo__btn {
  box-shadow: 0px 48px 48px rgba(0, 0, 0, 0.12), 0px 14px 20px rgba(35, 34, 87, 0.4);
}

@media only screen and (max-width: 767px) {
  .demo__item:nth-child(2) .demo__btn {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  }
}

@media only screen and (max-width: 767px) {
  .demo__item:not(:last-child) {
    margin-bottom: 24px;
  }
}

.demo__title {
  margin-bottom: 45px;
  font-size: 64px;
  letter-spacing: -.025em;
}

@media only screen and (max-width: 1199px) {
  .demo__title {
    font-size: 64px;
  }
}

@media only screen and (max-width: 1023px) {
  .demo__title {
    margin-bottom: 32px;
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .demo__title {
    max-width: 230px;
    margin-bottom: 24px;
    font-size: 32px;
  }
  .demo__title br {
    display: none;
  }
}

.demo__text {
  flex-grow: 1;
  margin-bottom: 112px;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -.045em;
  color: #999FAE;
}

@media only screen and (max-width: 1023px) {
  .demo__text {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .demo__text {
    max-width: 215px;
    margin-bottom: 46px;
    font-size: 15px;
    line-height: 1.5;
  }
}

.demo__btn {
  min-width: 100%;
  height: 82px;
  margin-top: auto;
}

@media only screen and (max-width: 1199px) {
  .demo__btn {
    height: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .demo__btn {
    height: 64px;
  }
}

.demo__circle {
  position: absolute;
  top: -206px;
  left: calc(50% - 406px);
  z-index: -1;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #FAB8C4;
}

@media only screen and (max-width: 767px) {
  .demo__circle {
    display: none;
  }
}

.design {
  position: relative;
  margin-bottom: 110px;
}

@media only screen and (max-width: 767px) {
  .design {
    margin-bottom: 80px;
  }
}

.design__wrap {
  max-width: 495px;
  margin-bottom: 108px;
}

@media only screen and (max-width: 1199px) {
  .design__wrap {
    margin-bottom: 155px;
  }
}

@media only screen and (max-width: 1023px) {
  .design__wrap {
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .design__wrap {
    margin-bottom: 42px;
  }
}

.design__stage {
  margin-bottom: 24px;
  color: #C75C6F;
}

@media only screen and (max-width: 767px) {
  .design__stage {
    margin-bottom: 16px;
  }
}

.design__title {
  margin-bottom: 48px;
}

@media only screen and (max-width: 1199px) {
  .design__title {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .design__title {
    margin-bottom: 37px;
  }
}

.design__info {
  max-width: 380px;
}

@media only screen and (max-width: 767px) {
  .design__info {
    max-width: 100%;
  }
}

@media only screen and (max-width: 666px) {
  .design__info {
    max-width: 230px;
  }
}

.design__hand, .design__bg, .design__circle, .design__preview {
  position: absolute;
  font-size: 0;
  pointer-events: none;
}

.design__hand {
  top: -130px;
  right: calc(50% - 775px);
  width: 620px;
}

@media only screen and (min-width: 1540px) {
  .design__hand {
    right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .design__hand {
    right: calc(50% - 725px);
  }
}

@media only screen and (max-width: 1023px) {
  .design__hand {
    top: -100px;
    right: -100px;
    width: 400px;
  }
}

@media only screen and (max-width: 767px) {
  .design__hand {
    display: none;
  }
}

.design__hand[data-aos] {
  transform: translateX(100px) rotate(30deg);
  opacity: 0;
  transition-property: transform, opacity;
}

.design__hand[data-aos].aos-animate {
  transform: translateX(0) rotate(0);
  opacity: 1;
}

.design__bg {
  top: -33px;
  right: calc(50% - 314px);
  width: 290px;
}

@media only screen and (max-width: 1199px) {
  .design__bg {
    right: calc(50% - 260px);
  }
}

@media only screen and (max-width: 1023px) {
  .design__bg {
    right: 180px;
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .design__bg {
    right: -30px;
    width: 150px;
  }
}

.design__bg[data-aos] {
  transform: scale(0.7);
  opacity: 0;
  transition-property: transform, opacity;
}

.design__bg[data-aos].aos-animate {
  transform: scale(1);
  opacity: 1;
}

.design__circle {
  z-index: -1;
  border-radius: 50%;
}

.design__circle:first-child {
  top: -150px;
  left: calc(50% - 316px);
  width: 90px;
  height: 90px;
  background: #FFDC60;
}

@media only screen and (max-width: 767px) {
  .design__circle:first-child {
    top: 60px;
    left: 142px;
  }
}

.design__circle:nth-child(2) {
  top: -164px;
  right: calc(50% - 11px);
  width: 32px;
  height: 32px;
  background: #FFDC60;
}

@media only screen and (max-width: 1023px) {
  .design__circle:nth-child(2) {
    top: -100px;
  }
}

@media only screen and (max-width: 767px) {
  .design__circle:nth-child(2) {
    top: 126px;
    right: -172px;
    width: 270px;
    height: 270px;
    background: #FAB8C4;
  }
}

.design__circle:nth-child(3) {
  top: -250px;
  right: calc(50% - 443px);
  width: 64px;
  height: 64px;
  background: #5956E9;
}

@media only screen and (max-width: 1023px) {
  .design__circle:nth-child(3) {
    right: -32px;
  }
}

@media only screen and (max-width: 767px) {
  .design__circle:nth-child(3) {
    top: -72px;
    right: 130px;
    width: 24px;
    height: 24px;
  }
}

.design__pic {
  width: 100%;
}

.design__scroll {
  display: inline-flex;
  margin-bottom: 80px;
  font-weight: 500;
  letter-spacing: -.04em;
  color: #999FAE;
}

@media only screen and (max-width: 767px) {
  .design__scroll {
    margin-bottom: 44px;
  }
}

.design__icon {
  flex-shrink: 0;
  width: 24px;
  margin-right: 16px;
  font-size: 0;
}

.design__container {
  margin: 0 -20px;
}

@media only screen and (max-width: 1199px) {
  .design__container {
    margin: 0 -12px;
  }
}

.design__slider {
  visibility: hidden;
}

.design__slider.slick-initialized {
  visibility: visible;
}

.design__slide {
  margin: 0 20px;
  padding: 40px 10px 10px;
  border-radius: 24px;
}

@media only screen and (max-width: 1199px) {
  .design__slide {
    margin: 0 12px;
  }
}

.design__slide:first-child, .design__slide:nth-child(4) {
  background: #FFEDDC;
}

.design__slide:first-child .design__view, .design__slide:nth-child(4) .design__view {
  background: #FAB8C4;
}

.design__slide:first-child .design__preview, .design__slide:nth-child(4) .design__preview {
  top: 10px;
  left: 15px;
  width: 360px;
}

.design__slide:nth-child(2), .design__slide:nth-child(5) {
  background: #DBDEFF;
}

.design__slide:nth-child(2) .design__view, .design__slide:nth-child(5) .design__view {
  background: #5956E9;
}

.design__slide:nth-child(2) .design__preview, .design__slide:nth-child(5) .design__preview {
  top: 52px;
  left: 50%;
  transform: translateX(-41%);
  width: 150px;
}

.design__slide:nth-child(2) .design__link, .design__slide:nth-child(5) .design__link {
  color: #5956E9;
}

.design__slide:nth-child(2) .design__link .icon, .design__slide:nth-child(5) .design__link .icon {
  fill: #5956E9;
}

.design__slide:nth-child(3) {
  background: #DBF8FF;
}

.design__slide:nth-child(3) .design__view {
  background: #90C8FC;
}

.design__slide:nth-child(3) .design__preview {
  top: 45px;
  left: 50%;
  transform: translateX(-51%);
  width: 150px;
}

.design__view {
  position: relative;
  width: 220px;
  height: 220px;
  margin: 0 auto 40px;
  border-radius: 50%;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .design__view {
    margin-bottom: 34px;
  }
}

.design__details {
  padding: 40px 30px;
  background: #fff;
  border-radius: 24px;
}

@media only screen and (max-width: 1199px) {
  .design__details {
    padding: 40px 24px;
  }
}

.design__category {
  margin-bottom: 23px;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .design__category {
    margin-bottom: 16px;
  }
}

.design__slide .design__info {
  margin-bottom: 23px;
  font-family: 'DM Sans', sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -.045em;
}

@media only screen and (max-width: 1199px) {
  .design__slide .design__info {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 1023px) {
  .design__slide .design__info {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .design__slide .design__info {
    font-size: 24px;
  }
}

.design__text {
  color: #999FAE;
}

@media only screen and (max-width: 1199px) {
  .design__text {
    max-width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .design__text {
    max-width: 100%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 666px) {
  .design__text {
    max-width: 180px;
  }
}

.design__link {
  display: inline-flex;
  align-items: center;
  margin-top: 26px;
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -.045em;
  color: #27272E;
}

@media only screen and (max-width: 1199px) {
  .design__link {
    margin-top: 20px;
  }
}

.design__link .icon {
  margin-top: -2px;
  margin-left: 16px;
  font-size: 14px;
  fill: #27272E;
  transition: transform .25s;
}

.design__link:hover .icon {
  transform: translateX(3px);
}

.design .slick-arrow {
  position: absolute;
  top: -110px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #27272E;
  font-size: 0;
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .design .slick-arrow {
    top: -77px;
  }
}

.design .slick-arrow .icon {
  font-size: 12px;
  fill: #fff;
}

.design .slick-arrow:hover {
  background: #5956E9;
}

.design .slick-prev {
  right: 77px;
}

@media only screen and (max-width: 1199px) {
  .design .slick-prev {
    right: 69px;
  }
}

.design .slick-next {
  right: 20px;
}

@media only screen and (max-width: 1199px) {
  .design .slick-next {
    right: 12px;
  }
}

.details {
  position: relative;
  z-index: 2;
  margin-bottom: 165px;
}

@media only screen and (max-width: 1023px) {
  .details {
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .details {
    margin-bottom: 80px;
  }
}

.details__head {
  margin-bottom: 61px;
}

@media only screen and (max-width: 767px) {
  .details__head {
    margin-bottom: 20px;
  }
}

.details__title {
  margin-bottom: 38px;
}

@media only screen and (max-width: 1199px) {
  .details__title {
    margin-bottom: 62px;
  }
}

@media only screen and (max-width: 1023px) {
  .details__title {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .details__title {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 1199px) {
  .details__title br {
    display: none;
  }
}

.details__info {
  letter-spacing: 0;
  color: #292930;
}

@media only screen and (max-width: 666px) {
  .details__info {
    max-width: 230px;
  }
}

.details__row {
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 767px) {
  .details__row {
    display: block;
  }
}

.details__container {
  flex: 0 0 calc(100% - 360px);
  width: calc(100% - 360px);
}

@media only screen and (max-width: 1023px) {
  .details__container {
    flex: 0 0 calc(100% - 300px);
    width: calc(100% - 300px);
  }
}

@media only screen and (max-width: 767px) {
  .details__container {
    width: 100%;
  }
}

.details__pic {
  width: 100%;
}

.details__graphic {
  position: relative;
  flex-shrink: 0;
  width: 360px;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .details__graphic {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .details__graphic {
    margin: 95px auto 0;
  }
}

@media only screen and (max-width: 474px) {
  .details__graphic {
    width: 100%;
  }
}

.details__box, .details__line, .details__circle, .details__hand {
  position: absolute;
  pointer-events: none;
  font-size: 0;
}

.details__box {
  right: 48px;
  left: 87px;
  bottom: 125px;
  overflow: hidden;
}

@media only screen and (max-width: 1023px) {
  .details__box {
    right: 30px;
    left: 70px;
    bottom: 107px;
  }
}

.details__chart[data-aos] {
  transform: translateY(100%);
  transition-property: transform;
}

.details__chart[data-aos].aos-animate {
  transform: translateY(0);
}

.details__line {
  left: 0;
  right: 0;
  bottom: 68px;
  z-index: 3;
}

@media only screen and (max-width: 1023px) {
  .details__line {
    display: none;
  }
}

.details__hand {
  top: 76.5%;
  right: calc(50% - 870px);
  width: 750px;
}

@media only screen and (min-width: 1600px) {
  .details__hand {
    right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .details__hand {
    top: 79%;
    right: calc(50% - 720px);
    width: 680px;
  }
}

@media only screen and (max-width: 1023px) {
  .details__hand {
    display: none;
  }
}

.details__hand:before {
  content: "";
  position: absolute;
  top: 160px;
  left: 0;
  z-index: -1;
  width: 106px;
  height: 106px;
  border-radius: 50%;
  background: #FFDC60;
}

.details__slider {
  visibility: hidden;
}

.details__slider.slick-initialized {
  visibility: visible;
}

.details__slide {
  padding: 0 190px 0 180px;
}

@media only screen and (max-width: 1199px) {
  .details__slide {
    padding: 0 120px 0 80px;
  }
}

@media only screen and (max-width: 1023px) {
  .details__slide {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .details__slide {
    padding: 0 0 0 70px;
  }
}

.details__counter {
  margin-bottom: 40px;
  font-family: 'DM Sans', sans-serif;
  font-size: 80px;
  font-weight: 500;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .details__counter {
    margin-bottom: 10px;
    font-size: 68px;
  }
}

.details__number {
  pointer-events: none;
  font-family: 'Poppins', sans-serif;
  font-size: 288px;
  font-weight: 300;
  letter-spacing: -.09em;
}

@media only screen and (max-width: 1023px) {
  .details__number {
    font-size: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .details__number {
    font-size: 216px;
  }
}

.details__text {
  max-width: 230px;
  margin-bottom: 65px;
}

@media only screen and (max-width: 767px) {
  .details__text {
    max-width: 100%;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 666px) {
  .details__text {
    max-width: 230px;
  }
}

.details__play {
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  margin-right: 47px;
  border-radius: 50%;
  background: #5956E9;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -.04em;
  color: #fff;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .details__play {
    margin-right: 20px;
  }
}

.details__play:hover {
  opacity: .9;
}

.details__link {
  position: relative;
  display: inline-block;
  padding-left: 50px;
  font-weight: 500;
  color: #27272E;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .details__link {
    padding: 0;
  }
}

.details__link:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 34px;
  height: 1px;
  background: #27272E;
}

@media only screen and (max-width: 767px) {
  .details__link:before {
    display: none;
  }
}

.details__link:hover {
  opacity: .8;
}

.details__circle {
  z-index: -1;
  border-radius: 50%;
}

.details__circle:first-child {
  left: calc(50% - 520px);
  bottom: -4px;
  width: 46px;
  height: 46px;
  background: #FAB8C4;
}

@media only screen and (max-width: 767px) {
  .details__circle:first-child {
    top: 51%;
    left: -23px;
    bottom: auto;
  }
}

.details__circle:nth-child(2) {
  top: 400px;
  right: calc(50% - 623px);
  width: 226px;
  height: 226px;
  background: #FAB8C4;
}

@media only screen and (max-width: 1199px) {
  .details__circle:nth-child(2) {
    right: calc(50% - 533px);
  }
}

@media only screen and (max-width: 767px) {
  .details__circle:nth-child(2) {
    top: auto;
    bottom: 520px;
    right: -62px;
    width: 197px;
    height: 197px;
  }
}

.details .slick-dots {
  position: absolute;
  top: 150px;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .details .slick-dots {
    top: 90px;
  }
}

.details .slick-dots li {
  font-size: 0;
}

.details .slick-dots li:not(:last-child) {
  margin-bottom: 24px;
}

.details .slick-dots li button {
  position: relative;
  width: 12px;
  height: 12px;
  font-size: 0;
}

.details .slick-dots li button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #27272E;
  transition: all .25s;
}

.details .slick-dots li.slick-active button:before {
  width: 12px;
  height: 12px;
  border: 3px solid #27272E;
  background: #fff;
}

.tool {
  margin-bottom: 110px;
}

@media only screen and (max-width: 767px) {
  .tool {
    margin-bottom: 135px;
  }
}

.tool__center {
  max-width: 1384px;
}

@media only screen and (max-width: 1199px) {
  .tool__center {
    padding: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .tool__center {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .tool__center {
    padding: 0;
  }
}

.tool__container {
  position: relative;
  padding: 120px 100px 137px;
  border-radius: 40px;
  background: #5956E9;
  color: #fff;
}

@media only screen and (max-width: 1199px) {
  .tool__container {
    padding: 120px 72px 137px;
    border-radius: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .tool__container {
    padding: 80px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .tool__container {
    padding: 172px 32px;
    text-align: center;
  }
}

.tool__wrap {
  max-width: 810px;
}

.tool__title {
  margin-bottom: 24px;
}

@media only screen and (max-width: 1199px) {
  .tool__title {
    max-width: 730px;
    margin-bottom: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .tool__title {
    margin-bottom: 24px;
  }
}

.tool__info {
  margin-bottom: 40px;
  letter-spacing: 0;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .tool__info {
    max-width: 220px;
    margin: 0 auto 60px;
  }
}

.tool__btn .btn {
  min-width: 230px;
  box-shadow: 0px 48px 48px rgba(0, 0, 0, 0.12), 0px 14px 20px rgba(35, 34, 87, 0.4);
}

@media only screen and (max-width: 767px) {
  .tool__btn .btn {
    min-width: 100%;
  }
}

.tool__cloud {
  position: absolute;
  left: 28%;
  bottom: -47px;
  width: 162px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .tool__cloud {
    left: 55%;
  }
}

@media only screen and (max-width: 767px) {
  .tool__cloud {
    left: 28%;
  }
}

.tool__pic {
  width: 100%;
}

.roadmap {
  position: relative;
  margin-bottom: 182px;
}

@media only screen and (max-width: 767px) {
  .roadmap {
    margin-bottom: 80px;
  }
}

.roadmap__head {
  position: relative;
  margin-bottom: 150px;
}

@media only screen and (max-width: 1023px) {
  .roadmap__head {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .roadmap__head {
    margin-bottom: 32px;
  }
}

.roadmap__wrap {
  max-width: 500px;
}

.roadmap__stage {
  margin-bottom: 24px;
  color: #FAB8C4;
}

@media only screen and (max-width: 767px) {
  .roadmap__stage {
    margin-bottom: 16px;
  }
}

.roadmap__title {
  margin-bottom: 48px;
}

@media only screen and (max-width: 1199px) {
  .roadmap__title {
    font-size: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .roadmap__title {
    margin-bottom: 40px;
  }
}

.roadmap__info {
  max-width: 320px;
  color: #999FAE;
}

.roadmap__select {
  display: none;
  min-width: 145px;
  height: 40px;
  margin-top: 32px;
  padding: 0 45px 0 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 8px;
  background: #E3E6E9 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='9' fill='none'%3E%3Cpath fill-rule='evenodd' d='M.293.293a1 1 0 0 1 1.414 0L7.5 6.086 13.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414l-6.5 6.5a1 1 0 0 1-1.414 0l-6.5-6.5a1 1 0 0 1 0-1.414z' fill='%23292930'/%3E%3C/svg%3E") no-repeat calc(100% - 15px) 50%/15px auto;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  color: #292930;
}

@media only screen and (max-width: 767px) {
  .roadmap__select {
    display: block;
  }
}

.roadmap__select::-ms-expand {
  display: none;
}

.roadmap__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.roadmap__view, .roadmap__bg, .roadmap__circle, .roadmap__preview {
  position: absolute;
  font-size: 0;
  pointer-events: none;
}

.roadmap__view {
  top: -65px;
  right: -202px;
  width: 774px;
}

@media only screen and (max-width: 1199px) {
  .roadmap__view {
    right: -250px;
    top: -20px;
    width: 650px;
  }
}

@media only screen and (max-width: 767px) {
  .roadmap__view {
    display: none;
  }
}

.roadmap__view[data-aos] .roadmap__calendar {
  transform: translateX(70px);
  opacity: 0;
  transition: all .6s;
}

.roadmap__view[data-aos].aos-animate .roadmap__calendar {
  transform: translateX(0);
  opacity: 1;
}

.roadmap__bg {
  position: absolute;
  top: 67px;
  right: 145px;
  width: 494px;
}

@media only screen and (max-width: 1199px) {
  .roadmap__bg {
    width: 440px;
  }
}

@media only screen and (max-width: 1023px) {
  .roadmap__bg {
    width: 360px;
  }
}

.roadmap__pic {
  width: 100%;
}

.roadmap__body {
  display: flex;
  max-width: 930px;
}

.roadmap__category {
  font-size: 12px;
  color: #999FAE;
}

@media only screen and (max-width: 767px) {
  .roadmap__body > .roadmap__category {
    display: none;
  }
}

.roadmap__body > .roadmap__category:first-child {
  margin-right: 12px;
}

.roadmap__year {
  font-size: 24px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .roadmap__year {
    font-size: 18px;
  }
}

.roadmap__box {
  position: relative;
  flex-shrink: 0;
  width: 540px;
  margin: 0 auto;
  padding-top: 42px;
}

@media only screen and (max-width: 767px) {
  .roadmap__box {
    width: calc(100% + 20px);
    margin-right: -20px;
  }
}

.roadmap__top {
  display: flex;
}

.roadmap__col {
  position: relative;
  width: 1px;
  height: 353px;
  background: #E3E6E9;
}

@media only screen and (max-width: 767px) {
  .roadmap__col {
    height: 248px;
  }
}

.roadmap__col:not(:last-child) {
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .roadmap__col:last-child {
    margin-right: auto;
  }
}

.roadmap__desc {
  position: absolute;
  left: -25px;
  bottom: 100%;
}

@media only screen and (max-width: 767px) {
  .roadmap__desc {
    left: 0;
  }
}

.roadmap__lines {
  position: absolute;
  top: 118px;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .roadmap__lines {
    top: 82px;
    right: -32px;
  }
}

.roadmap__line {
  position: relative;
  height: 48px;
  background: rgba(137, 107, 167, 0.1);
  overflow: hidden;
}

.roadmap__line:nth-child(2) {
  margin-left: 33.33%;
}

@media only screen and (max-width: 767px) {
  .roadmap__line:nth-child(2) {
    margin-left: 23%;
  }
}

.roadmap__line:nth-child(2) .roadmap__progress {
  padding: 0 16px;
}

@media only screen and (max-width: 767px) {
  .roadmap__line:nth-child(2) .roadmap__progress {
    padding: 0 12px;
  }
}

.roadmap__line:not(:last-child) {
  margin-bottom: 32px;
}

.roadmap__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: #896BA7;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .roadmap__progress {
    padding: 0 16px;
    font-size: 14px;
  }
}

.roadmap__progress[data-aos] {
  transform: translateX(-100%);
  transition-property: transform;
}

.roadmap__progress[data-aos].aos-animate {
  transform: translateX(0);
}

.roadmap__circle {
  z-index: -1;
  border-radius: 50%;
}

.roadmap__circle:first-child {
  left: calc(50% - 567px);
  bottom: 165px;
  width: 90px;
  height: 90px;
  background: rgba(250, 184, 196, 0.5);
}

@media only screen and (max-width: 1199px) {
  .roadmap__circle:first-child {
    left: calc(50% - 440px);
  }
}

@media only screen and (max-width: 767px) {
  .roadmap__circle:first-child {
    top: -90px;
    right: -27px;
    left: auto;
    bottom: auto;
    width: 55px;
    height: 55px;
    background: #FAB8C4;
  }
}

.roadmap__circle:nth-child(2) {
  top: 27px;
  left: calc(50% - 168px);
  width: 120px;
  height: 120px;
  background: #FFDC60;
}

@media only screen and (max-width: 1199px) {
  .roadmap__circle:nth-child(2) {
    left: calc(50% - 85px);
  }
}

@media only screen and (max-width: 767px) {
  .roadmap__circle:nth-child(2) {
    top: 20px;
    left: auto;
    right: 30px;
  }
}

.roadmap__preview {
  position: absolute;
  bottom: -134px;
  right: calc(50% - 718px);
  z-index: -1;
}

@media only screen and (max-width: 1199px) {
  .roadmap__preview {
    right: calc(50% - 774px);
  }
}

@media only screen and (max-width: 767px) {
  .roadmap__preview {
    display: none;
  }
}

.roadmap__preview[data-aos] {
  transform: rotate(-30deg);
  transition-property: transform;
}

.roadmap__preview[data-aos].aos-animate {
  transform: rotate(0);
}

.accord {
  margin-bottom: 88px;
}

@media only screen and (max-width: 767px) {
  .accord {
    margin-bottom: 80px;
  }
}

.accord__row {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .accord__row {
    display: block;
  }
}

.accord__details {
  flex-grow: 1;
  padding: 33px 50px 0 0;
}

@media only screen and (max-width: 1023px) {
  .accord__details {
    margin-bottom: 40px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .accord__details {
    margin-bottom: 32px;
  }
}

.accord__wrap {
  flex-shrink: 0;
  width: 598px;
}

@media only screen and (max-width: 1199px) {
  .accord__wrap {
    width: 462px;
  }
}

@media only screen and (max-width: 1023px) {
  .accord__wrap {
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .accord__wrap {
    margin: 0 -8px;
  }
}

.accord__title {
  margin-bottom: 40px;
  font-size: 48px;
}

@media only screen and (max-width: 1199px) {
  .accord__title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 1023px) {
  .accord__title {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .accord__title {
    margin-bottom: 32px;
  }
}

.accord__top {
  margin-bottom: 65px;
}

@media only screen and (max-width: 1199px) {
  .accord__top {
    margin-bottom: 56px;
  }
}

@media only screen and (max-width: 1023px) {
  .accord__top {
    margin-bottom: 32px;
  }
}

.accord__text {
  max-width: 320px;
  color: #999FAE;
}

.accord__list li {
  position: relative;
  padding-left: 44px;
}

.accord__list li:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #5956E9;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M8.7.286A1 1 0 0 1 8.714 1.7l-4.9 5a1 1 0 0 1-1.475-.051L.24 4.187a1 1 0 0 1 .112-1.41 1 1 0 0 1 1.41.112l1.4 1.63L7.286.3A1 1 0 0 1 8.7.286z' fill='%23fff'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 9px 7px;
}

.accord__list li:nth-child(2n+1):before {
  background-color: #FAB8C4;
}

.accord__list li:not(:last-child) {
  margin-bottom: 16px;
}

.accord__item {
  padding: 8px 7px 0;
  border-radius: 24px;
  transition: all .25s;
}

.accord__item.active {
  box-shadow: 0 5px 40px rgba(39, 39, 46, 0.1);
}

.accord__item:not(:last-child) {
  margin-bottom: 8px;
}

.accord__head {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 80px 16px 24px;
  border-radius: 16px;
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.1;
  color: #27272E;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all .25s;
}

@media only screen and (max-width: 767px) {
  .accord__head {
    padding: 16px 60px 16px 24px;
    transition: background .25s, color .25s;
  }
}

.accord__head:before, .accord__head:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 2px;
  background: #27272E;
  transition: all .25s;
}

.accord__head:before {
  right: 44px;
  width: 16px;
  height: 2px;
}

@media only screen and (max-width: 767px) {
  .accord__head:before {
    right: 24px;
  }
}

.accord__head:after {
  right: 51px;
  width: 2px;
  height: 16px;
}

@media only screen and (max-width: 767px) {
  .accord__head:after {
    right: 31px;
  }
}

.accord__head:hover {
  color: #5956E9;
}

.accord__item.active .accord__head {
  padding: 24px 80px 24px 24px;
  background: #5956E9;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .accord__item.active .accord__head {
    padding: 16px 60px 16px 24px;
  }
}

.accord__item.active .accord__head:before, .accord__item.active .accord__head:after {
  background: #fff;
}

.accord__item.active .accord__head:after {
  transform: translateY(-50%) rotate(90deg);
}

.accord__icon {
  flex-shrink: 0;
  width: 32px;
  margin-right: 24px;
  font-size: 0;
}

.accord__pic {
  width: 100%;
}

.accord__body {
  display: none;
  padding: 60px 55px 92px 302px;
  background-repeat: no-repeat;
  background-position: 45px 60%;
  background-size: auto 260px;
}

@media only screen and (max-width: 1199px) {
  .accord__body {
    padding: 32px 24px 40px;
    background: #fff !important;
  }
}

@media only screen and (max-width: 767px) {
  .accord__body {
    padding: 32px 20px;
  }
}

.accord__desc li {
  position: relative;
  padding-left: 36px;
  color: #999FAE;
}

.accord__desc li:before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 24px;
  height: 24px;
  background: url("../img/list-icon-2.svg") no-repeat 50% 50%/100% auto;
}

.accord__desc li:not(:last-child) {
  margin-bottom: 24px;
}

.partners {
  position: relative;
  z-index: 2;
  padding: 111px 0 62px;
  overflow: hidden;
  background: #27272E;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .partners {
    padding: 80px 0 52px;
  }
}

.partners__center {
  position: relative;
  z-index: 2;
}

.partners__head {
  display: flex;
  margin-bottom: 78px;
}

@media only screen and (max-width: 1199px) {
  .partners__head {
    margin-bottom: 110px;
  }
}

@media only screen and (max-width: 1023px) {
  .partners__head {
    display: block;
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .partners__head {
    margin-bottom: 8px;
  }
}

.partners__stage {
  flex-shrink: 0;
  width: 270px;
  padding-top: 8px;
}

@media only screen and (max-width: 1199px) {
  .partners__stage {
    width: 225px;
  }
}

@media only screen and (max-width: 1023px) {
  .partners__stage {
    width: 100%;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .partners__stage {
    margin-bottom: 16px;
  }
}

.partners__details {
  flex-grow: 1;
}

.partners__title {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .partners__title {
    margin-bottom: 32px;
  }
}

.partners__info {
  max-width: 500px;
  color: rgba(255, 255, 255, 0.7);
}

.partners__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

@media only screen and (max-width: 1199px) {
  .partners__list {
    margin: 0 -16px;
  }
}

.partners__item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 calc(25% - 40px);
  width: calc(25% - 40px);
  height: 133px;
  margin: 0 20px;
  font-size: 0;
  filter: grayscale(100%);
}

@media only screen and (max-width: 1199px) {
  .partners__item {
    flex: 0 0 calc(25% - 32px);
    width: calc(25% - 32px);
    margin: 0 16px;
  }
}

@media only screen and (max-width: 767px) {
  .partners__item {
    flex: 0 0 50%;
    width: 50%;
    height: 110px;
    margin: 0;
    padding: 0 15px;
    filter: grayscale(0);
  }
}

.partners__item:hover {
  filter: grayscale(0);
  opacity: 1;
}

.partners__item[data-aos] {
  transform: scaleY(2);
  opacity: 0;
  transition-property: transform, opacity;
}

.partners__item[data-aos].aos-animate {
  transform: scaleY(1);
  opacity: 1;
}

.partners__item .partners__pic {
  max-width: 100%;
  max-height: 100%;
}

.partners__preview, .partners__circle {
  position: absolute;
  font-size: 0;
  pointer-events: none;
}

.partners__preview {
  right: 50%;
  top: 0;
}

@media only screen and (max-width: 1199px) {
  .partners__preview {
    right: 42%;
  }
}

@media only screen and (max-width: 767px) {
  .partners__preview {
    right: -200px;
  }
}

.partners__preview[data-aos] {
  transform: rotate(30deg);
  transition-property: transform;
}

.partners__preview[data-aos].aos-animate {
  transform: scaleY(1);
}

.partners__circle {
  top: -258px;
  right: calc(50% - 522px);
  width: 268px;
  height: 268px;
  border-radius: 50%;
  background: #525260;
}

@media only screen and (max-width: 1199px) {
  .partners__circle {
    top: 113px;
    right: -88px;
    width: 248px;
    height: 248px;
  }
}

@media only screen and (max-width: 767px) {
  .partners__circle {
    top: 130px;
    right: -80px;
  }
}

.quality {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .quality {
    padding: 72px 0 76px;
    text-align: center;
  }
}

.quality__head {
  position: relative;
  padding: 300px 0 90px;
}

@media only screen and (max-width: 1199px) {
  .quality__head {
    padding-bottom: 130px;
  }
}

@media only screen and (max-width: 1023px) {
  .quality__head {
    padding: 240px 0 90px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__head {
    margin-bottom: 64px;
    padding: 0;
  }
}

.quality__stage {
  margin-bottom: 24px;
  color: #C75C6F;
}

@media only screen and (max-width: 767px) {
  .quality__stage {
    margin-bottom: 16px;
  }
}

.quality__top {
  margin-bottom: 48px;
}

@media only screen and (max-width: 767px) {
  .quality__top {
    margin-bottom: 32px;
  }
}

.quality__info {
  max-width: 410px;
}

@media only screen and (max-width: 767px) {
  .quality__info {
    max-width: 100%;
  }
}

.quality__line, .quality__preview, .quality__circle, .quality__icon {
  position: absolute;
  z-index: -1;
  pointer-events: none;
}

.quality__line {
  position: absolute;
  top: 0;
  left: calc(50% - 517px);
  bottom: -1px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .quality__line {
    left: calc(50% - 438px);
  }
}

@media only screen and (max-width: 767px) {
  .quality__line {
    top: -45px;
    left: auto;
    right: -360px;
    bottom: auto;
  }
}

.quality__line .quality__pic {
  width: auto;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .quality__line .quality__pic {
    width: 600px;
  }
}

.quality__preview {
  top: 126px;
  right: calc(50% - 724px);
  width: 480px;
  font-size: 0;
}

@media only screen and (max-width: 1199px) {
  .quality__preview {
    top: 156px;
    right: calc(50% - 654px);
    width: 460px;
  }
}

@media only screen and (max-width: 1023px) {
  .quality__preview {
    top: 90px;
    right: -180px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__preview {
    display: none;
  }
}

.quality__pic {
  width: 100%;
}

.quality__body {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #E3E6E9;
}

@media only screen and (max-width: 767px) {
  .quality__body {
    display: block;
    border: none;
  }
}

.quality__col {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  width: 50%;
  height: 444px;
  padding-top: 5px;
  border-bottom: 1px solid #E3E6E9;
}

@media only screen and (max-width: 1023px) {
  .quality__col {
    height: 380px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__col {
    width: 100%;
    height: auto;
    border: none;
  }
}

.quality__col:nth-child(2n+1) {
  border-right: 1px solid #E3E6E9;
}

@media only screen and (max-width: 767px) {
  .quality__col:nth-child(2n+1) {
    border: none;
  }
}

.quality__col:nth-child(2n+1) .quality__item {
  margin-left: auto;
  margin-right: 100px;
}

@media only screen and (max-width: 1199px) {
  .quality__col:nth-child(2n+1) .quality__item {
    margin: 0 72px;
  }
}

@media only screen and (max-width: 1023px) {
  .quality__col:nth-child(2n+1) .quality__item {
    margin: 0 20px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__col:nth-child(2n+1) .quality__item {
    margin: 0 32px;
  }
}

.quality__col:nth-child(2n) .quality__item {
  margin-left: 100px;
}

@media only screen and (max-width: 1199px) {
  .quality__col:nth-child(2n) .quality__item {
    margin: 0 72px;
  }
}

@media only screen and (max-width: 1023px) {
  .quality__col:nth-child(2n) .quality__item {
    margin: 0 30px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__col:nth-child(2n) .quality__item {
    margin: 0 32px;
  }
}

.quality__col:nth-child(1) .quality__icon {
  top: 3px;
  left: -18px;
  width: 295px;
}

@media only screen and (max-width: 767px) {
  .quality__col:nth-child(1) .quality__icon {
    left: 50%;
    transform: translateX(-27%);
  }
}

.quality__col:nth-child(2) .quality__icon {
  top: -11px;
  left: -71px;
  width: 255px;
}

@media only screen and (max-width: 767px) {
  .quality__col:nth-child(2) .quality__icon {
    top: 0;
    left: 50%;
    transform: translateX(-54%);
    width: 235px;
  }
}

.quality__col:nth-child(2) .quality__link {
  color: #5956E9;
}

.quality__col:nth-child(2) .quality__link .icon {
  fill: #5956E9;
}

.quality__col:nth-child(3) .quality__icon {
  top: 0;
  left: -33px;
  width: 202px;
}

@media only screen and (max-width: 767px) {
  .quality__col:nth-child(3) .quality__icon {
    left: 50%;
    transform: translateX(-46%);
    width: 180px;
  }
}

.quality__col:nth-child(4) .quality__icon {
  top: 0;
  left: -162px;
  width: 348px;
}

@media only screen and (max-width: 767px) {
  .quality__col:nth-child(4) .quality__icon {
    left: 50%;
    transform: translateX(-68%);
  }
}

@media only screen and (max-width: 767px) {
  .quality__col:not(:last-child) {
    margin-bottom: 56px;
  }
}

.quality__item {
  position: relative;
  max-width: 420px;
  padding-left: 180px;
}

@media only screen and (max-width: 1023px) {
  .quality__item {
    padding-left: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__item {
    width: 100%;
    max-width: 100%;
    padding: 140px 0 0;
  }
}

.quality__category {
  margin-bottom: 24px;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.quality__text {
  margin-bottom: 46px;
  color: #999FAE;
}

@media only screen and (max-width: 1023px) {
  .quality__text {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__text {
    margin-bottom: 24px;
  }
}

.quality__link {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  color: #292930;
}

.quality__link .icon {
  margin-left: 20q;
  font-size: 14px;
  fill: #292930;
  transition: transform .25s;
}

.quality__link:hover .icon {
  transform: translateX(3px);
}

.quality__circle {
  border-radius: 50%;
}

.quality__circle:first-child {
  top: -74px;
  right: calc(50% - 577px);
  width: 148px;
  height: 148px;
  background: #5956E9;
}

@media only screen and (max-width: 1199px) {
  .quality__circle:first-child {
    right: 204px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__circle:first-child {
    top: 430px;
    right: 38px;
    width: 38px;
    height: 38px;
  }
}

.quality__circle:nth-child(2) {
  top: 356px;
  right: calc(50% - 618px);
  width: 40px;
  height: 40px;
  background: #FFDC60;
}

@media only screen and (max-width: 767px) {
  .quality__circle:nth-child(2) {
    top: -28px;
    left: 44px;
    width: 70px;
    height: 70px;
    background: #FAB8C4;
  }
}

.showcase {
  position: relative;
  height: 1963px;
  margin-bottom: 100px;
  padding-top: 112px;
  overflow: hidden;
  background: #ECF2F6;
}

@media only screen and (max-width: 1199px) {
  .showcase {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .showcase {
    height: 1500px;
    padding-top: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase {
    height: 1115px;
    margin-bottom: 0;
    padding-top: 72px;
  }
}

.showcase__wrap {
  position: relative;
  z-index: 3;
  max-width: 500px;
}

@media only screen and (max-width: 767px) {
  .showcase__wrap {
    max-width: 100%;
    text-align: center;
  }
}

.showcase__stage {
  margin-bottom: 24px;
  color: #5956E9;
}

@media only screen and (max-width: 767px) {
  .showcase__stage {
    margin-bottom: 16px;
  }
}

.showcase__title {
  margin-bottom: 48px;
}

@media only screen and (max-width: 1023px) {
  .showcase__title {
    margin-bottom: 32px;
  }
}

.showcase__info {
  max-width: 410px;
  margin-bottom: 45px;
}

@media only screen and (max-width: 767px) {
  .showcase__info {
    margin: 0;
  }
}

.showcase__btn .btn {
  min-width: 233px;
  height: 82px;
}

@media only screen and (max-width: 767px) {
  .showcase__btn .btn {
    display: none;
  }
}

.showcase__bg, .showcase__circle, .showcase__list {
  position: absolute;
  font-size: 0;
}

.showcase__bg, .showcase__circle {
  z-index: 1;
}

.showcase__bg {
  top: 112px;
  right: calc(50% - 904px);
}

@media only screen and (max-width: 1199px) {
  .showcase__bg {
    right: calc(50% - 950px);
  }
}

@media only screen and (max-width: 767px) {
  .showcase__bg {
    top: 382px;
    right: -140px;
    width: 411px;
  }
}

.showcase__bg .showcase__pic {
  width: 100%;
}

.showcase__circle {
  border-radius: 50%;
}

.showcase__circle:first-child {
  top: 207px;
  left: calc(50% - 814px);
  width: 191px;
  height: 191px;
  background: #FAB8C4;
}

.showcase__circle:nth-child(2) {
  top: 642px;
  left: calc(50% - 560px);
  width: 80px;
  height: 80px;
  background: #fff;
}

@media only screen and (max-width: 1199px) {
  .showcase__circle:nth-child(2) {
    left: 32px;
  }
}

@media only screen and (max-width: 1023px) {
  .showcase__circle:nth-child(2) {
    top: 550px;
  }
}

.showcase__list {
  top: 454px;
  left: 50%;
  transform: translateX(-35.6%) rotate(-30deg);
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .showcase__list {
    top: 350px;
  }
}

.showcase__preview {
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .showcase__preview {
    width: 1000px;
  }
}

@media only screen and (max-width: 1023px) {
  .showcase__preview:first-child {
    width: 1400px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase__preview:first-child {
    width: 1000px;
  }
}

.showcase__preview:nth-child(2) {
  position: relative;
  z-index: 2;
  margin-top: -220px;
}

@media only screen and (max-width: 1023px) {
  .showcase__preview:nth-child(2) {
    margin-top: -150px;
    width: 1160px;
  }
}

@media only screen and (max-width: 767px) {
  .showcase__preview:nth-child(2) {
    width: 830px;
    margin-top: -105px;
  }
}

@media only screen and (max-width: 1023px) {
  .showcase__preview .showcase__pic {
    width: 100%;
  }
}

.offer {
  position: relative;
  margin-bottom: 364px;
}

@media only screen and (max-width: 767px) {
  .offer {
    margin-bottom: 210px;
  }
}

.offer__center {
  max-width: 1384px;
}

@media only screen and (max-width: 767px) {
  .offer__center {
    padding: 0;
  }
}

.offer__container {
  position: relative;
  min-height: 701px;
  overflow: hidden;
  padding: 72px;
  border-radius: 40px;
  background: url("../img/bg-offer.png") no-repeat 50% 50%/cover;
  color: #fff;
}

@media only screen and (max-width: 1023px) {
  .offer__container {
    padding: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .offer__container {
    min-height: 628px;
  }
}

@media only screen and (max-width: 767px) {
  .offer__container {
    padding: 80px 32px 0;
    border-radius: 0;
  }
}

.offer__head {
  position: relative;
  z-index: 2;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;
}

.offer__title {
  margin-bottom: 24px;
}

@media only screen and (max-width: 1023px) {
  .offer__title {
    font-size: 54px;
  }
}

@media only screen and (max-width: 767px) {
  .offer__title {
    margin-bottom: 32px;
  }
}

.offer__info {
  margin-bottom: 44px;
  color: #fff;
}

@media only screen and (max-width: 1199px) {
  .offer__info {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .offer__info {
    margin-bottom: 40px;
  }
}

.offer__btn .btn {
  min-width: 230px;
  box-shadow: 0px 48px 48px rgba(0, 0, 0, 0.12), 0px 14px 20px rgba(35, 34, 87, 0.4);
}

.offer__circle {
  position: absolute;
  border-radius: 50%;
}

.offer__circles .offer__circle:first-child {
  top: -290px;
  left: 50%;
  width: 579px;
  height: 579px;
  margin-left: -290px;
  background: rgba(104, 101, 255, 0.5);
}

.offer__circles .offer__circle:nth-child(2) {
  left: 273px;
  bottom: 192px;
  width: 80px;
  height: 80px;
  background: #FAB8C4;
}

@media only screen and (max-width: 1199px) {
  .offer__circles .offer__circle:nth-child(2) {
    left: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .offer__circles .offer__circle:nth-child(2) {
    top: 396px;
    left: auto;
    right: -40px;
  }
}

.offer__center > .offer__circle {
  top: -22px;
  right: calc(50% - 430px);
  width: 50px;
  height: 50px;
  background: #FAB8C4;
}

@media only screen and (max-width: 1199px) {
  .offer__center > .offer__circle {
    right: 125px;
  }
}

.offer__view {
  position: absolute;
  top: 350px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .offer__view {
    top: auto;
    bottom: -150px;
  }
}

.offer__view[data-aos] .offer__preview {
  opacity: 0;
  transition: all .4s;
}

.offer__view[data-aos] .offer__preview:first-child {
  transform: translate(50px, -50px);
  transition-delay: .4s;
}

.offer__view[data-aos] .offer__preview:nth-child(2) {
  transform: translateX(50px) rotate(-20deg);
  transition-delay: .6s;
}

.offer__view[data-aos] .offer__preview:nth-child(3) {
  transform: translateX(-70px);
  transition-delay: .8s;
}

.offer__view[data-aos] .offer__preview:nth-child(4) {
  transform: translateX(40px);
  transition-delay: .2s;
}

.offer__view[data-aos] .offer__preview:nth-child(5) {
  transform: translateY(50px);
}

.offer__view[data-aos].aos-animate .offer__preview {
  opacity: 1;
}

.offer__view[data-aos].aos-animate .offer__preview:first-child {
  transform: translate(0, 0);
}

.offer__view[data-aos].aos-animate .offer__preview:nth-child(2) {
  transform: translateX(0) rotate(0);
}

.offer__view[data-aos].aos-animate .offer__preview:nth-child(3) {
  transform: translateX(0);
}

.offer__view[data-aos].aos-animate .offer__preview:nth-child(4) {
  transform: translateX(0);
}

.offer__view[data-aos].aos-animate .offer__preview:nth-child(5) {
  transform: translateY(0);
}

.offer__preview:first-child {
  top: 110px;
  right: -71px;
  width: 332px;
}

@media only screen and (max-width: 1199px) {
  .offer__preview:first-child {
    right: -37px;
    width: 285px;
  }
}

@media only screen and (max-width: 767px) {
  .offer__preview:first-child {
    top: 55px;
    width: 180px;
  }
}

.offer__preview:nth-child(2) {
  top: 287px;
  right: -91px;
  width: 164px;
}

@media only screen and (max-width: 1199px) {
  .offer__preview:nth-child(2) {
    top: 313px;
    right: -11px;
    width: 90px;
  }
}

@media only screen and (max-width: 1023px) {
  .offer__preview:nth-child(2) {
    right: 64px;
  }
}

@media only screen and (max-width: 767px) {
  .offer__preview:nth-child(2) {
    top: 150px;
    right: 16px;
    width: 65px;
  }
}

.offer__preview:nth-child(3) {
  left: -192px;
  bottom: 89px;
  width: 416px;
}

@media only screen and (max-width: 1199px) {
  .offer__preview:nth-child(3) {
    left: -65px;
    bottom: 93px;
    width: 280px;
  }
}

@media only screen and (max-width: 1023px) {
  .offer__preview:nth-child(3) {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .offer__preview:nth-child(3) {
    bottom: 32px;
    width: 177px;
  }
}

.offer__preview:nth-child(4) {
  right: 99px;
  bottom: 13px;
  width: 120px;
}

@media only screen and (max-width: 767px) {
  .offer__preview:nth-child(4) {
    right: 54px;
    bottom: 8px;
    width: 61px;
  }
}

@media only screen and (max-width: 767px) {
  .offer__preview:nth-child(5) {
    width: 430px;
  }
}

.offer__preview:not(:last-child) {
  position: absolute;
  z-index: 2;
}

.offer__preview:not(:last-child) .offer__pic {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .offer__pic {
    width: 100%;
  }
}

.footer__row {
  display: flex;
  margin-right: -40px;
  margin-bottom: 73px;
}

@media only screen and (max-width: 1023px) {
  .footer__row {
    margin-right: -60px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__row {
    display: block;
    margin: 0 0 32px;
  }
}

.footer__col {
  flex: 0 0 25%;
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .footer__col {
    width: 100%;
    margin-bottom: 32px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer__col:first-child {
    margin-bottom: 56px;
    text-align: center;
  }
}

.footer__col:not(:first-child) {
  padding-top: 8px;
}

@media only screen and (max-width: 767px) {
  .footer__col:not(:first-child) {
    padding: 0;
  }
}

.footer__logo {
  display: inline-block;
  font-size: 0;
}

.footer__logo .footer__pic {
  width: 145px;
}

.footer__category {
  margin-bottom: 50px;
  color: #999FAE;
}

@media only screen and (max-width: 767px) {
  .footer__category {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #E3E6E9;
    margin: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: all .25s;
  }
}

.footer__category .icon {
  display: none;
}

@media only screen and (max-width: 767px) {
  .footer__category .icon {
    display: inline-block;
    margin-left: auto;
    font-size: 10px;
    fill: #999FAE;
    transition: fill .25s;
  }
}

.footer__col.active .footer__category {
  border-color: #5956E9;
  color: #5956E9;
}

.footer__col.active .footer__category .icon {
  fill: #5956E9;
}

.footer__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (min-width: 768px) {
  .footer__menu {
    display: flex !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer__menu {
    display: none;
    padding-top: 24px;
  }
}

.footer__link {
  color: #292930;
  transition: color .25s;
}

@media only screen and (max-width: 767px) {
  .footer__link {
    display: block;
  }
}

.footer__link:hover {
  color: #5956E9;
}

.footer__link:not(:last-child) {
  margin-bottom: 16px;
}

.footer__bottom {
  display: flex;
  align-items: center;
  padding: 26px 0 50px;
  border-top: 1px solid #E3E6E9;
}

@media only screen and (max-width: 767px) {
  .footer__bottom {
    padding: 0 0 32px;
    border: none;
  }
}

.footer__company {
  margin-right: 16px;
  font-size: 0;
}

.footer__copyright {
  margin-right: auto;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #999FAE;
}

.footer__scroll {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #999FAE;
  transition: opacity .25s;
}

@media only screen and (max-width: 767px) {
  .footer__scroll {
    font-size: 0;
  }
}

.footer__scroll:hover {
  opacity: .85;
}

.footer__icon {
  margin-right: 16px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .footer__icon {
    margin: 0;
  }
}

.entry {
  position: relative;
  overflow: hidden;
}

.entry__center {
  display: flex;
  flex-direction: column;
  max-width: 1384px;
  min-height: 1024px;
  height: 100vh;
}

@media only screen and (max-width: 1199px) {
  .entry__center {
    min-height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .entry__center {
    padding-bottom: 40px;
  }
}

.entry__head {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  padding: 48px 0;
}

@media only screen and (max-width: 1199px) {
  .entry__head {
    margin-bottom: 40px;
    padding: 40px 0;
  }
}

@media only screen and (max-width: 1023px) {
  .entry__head {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .entry__head {
    display: block;
    text-align: center;
  }
}

.entry__logo {
  display: inline-block;
  flex-shrink: 0;
  width: 145px;
  margin-right: auto;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .entry__logo {
    width: 100px;
    margin: 0;
  }
}

.entry__pic {
  width: 100%;
}

.entry__pic_black {
  display: none;
}

@media only screen and (max-width: 767px) {
  .entry__pic_black {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .entry__pic_white {
    display: none;
  }
}

.entry__link {
  transition: opacity .25s;
}

.entry__link:hover {
  opacity: .8;
}

.entry__head .entry__link {
  margin-right: 32px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  color: #292930;
}

@media only screen and (max-width: 767px) {
  .entry__head .entry__link {
    display: none;
  }
}

.entry__head .entry__btn {
  min-width: 173px;
}

@media only screen and (max-width: 767px) {
  .entry__head .entry__btn {
    display: none;
  }
}

.entry__body {
  flex-grow: 1;
}

.entry__row {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .entry__row {
    display: block;
  }
}

.entry__details {
  max-width: 450px;
  margin-right: auto;
  color: #fff;
}

@media only screen and (max-width: 1199px) {
  .entry__details {
    max-width: 330px;
  }
}

@media only screen and (max-width: 1023px) {
  .entry__details {
    display: none;
  }
}

.entry__title {
  margin-bottom: 16px;
  font-size: 48px;
}

@media only screen and (max-width: 1023px) {
  .entry__title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .entry__title {
    font-size: 40px;
  }
}

.entry__details .entry__info {
  max-width: 280px;
}

.entry__form {
  width: 100%;
  max-width: 386px;
  margin-right: 107px;
}

@media only screen and (max-width: 1365px) {
  .entry__form {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1023px) {
  .entry__form {
    margin: 0 auto;
    text-align: center;
  }
}

.entry__form .entry__info {
  color: #999FAE;
}

.entry__btns .entry__btn {
  min-width: 100%;
}

.entry__btns .entry__btn:not(:last-child) {
  margin-bottom: 16px;
}

.entry__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.entry__links .entry__link {
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .entry__links .entry__link {
    font-size: 15px;
  }
}

.entry__links .entry__link:first-child {
  display: none;
  color: #999FAE;
}

@media only screen and (max-width: 767px) {
  .entry__links .entry__link:first-child {
    display: inline-block;
  }
}

.entry__links .entry__link:nth-child(2) {
  color: #5956E9;
}

.entry__links .entry__link:not(:last-child) {
  margin-bottom: 16px;
}

.entry__field:not(:last-child) {
  margin-bottom: 32px;
}

@media only screen and (max-width: 767px) {
  .entry__field:not(:last-child) {
    margin-bottom: 24px;
  }
}

.entry__bg, .entry__hand, .entry__img, .entry__circle, .entry__cube {
  position: absolute;
  font-size: 0;
  pointer-events: none;
}

.entry__bg {
  top: 0;
  left: calc(50% - 1070px);
  z-index: -2;
  width: 1050px;
}

@media only screen and (max-width: 1199px) {
  .entry__bg {
    left: calc(50% - 1030px);
  }
}

@media only screen and (max-width: 1023px) {
  .entry__bg {
    display: none;
  }
}

.entry__hand {
  top: 460px;
  left: calc(50% - 800px);
  width: 880px;
}

@media only screen and (min-width: 1600px) {
  .entry__hand {
    left: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .entry__hand {
    top: 400px;
    left: -100px;
    width: 700px;
  }
}

.entry__img {
  top: 394px;
  left: calc(50% - 686px);
  width: 742px;
}

@media only screen and (max-width: 1199px) {
  .entry__img {
    left: calc(50% - 486px);
    width: 500px;
  }
}

.entry__foot {
  padding: 50px 0;
}

@media only screen and (max-width: 1199px) {
  .entry__foot {
    display: none;
  }
}

.entry__author {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: -.05em;
  color: #999FAE;
  transition: opacity .25s;
}

.entry__author:hover {
  opacity: .8;
}

.entry__author .entry__icon {
  width: 40px;
  margin-left: 16px;
  font-size: 0;
}

.entry__circle {
  z-index: -1;
  border-radius: 50%;
}

.entry__circle:first-child {
  top: 161px;
  left: calc(50% - 280px);
  width: 24px;
  height: 24px;
  background: #fff;
}

@media only screen and (max-width: 1023px) {
  .entry__circle:first-child {
    top: -250px;
    left: -250px;
    width: 500px;
    height: 500px;
    background: #5956E9;
  }
}

@media only screen and (max-width: 767px) {
  .entry__circle:first-child {
    top: -97px;
    left: -97px;
    width: 195px;
    height: 195px;
  }
}

.entry__circle:nth-child(2) {
  top: 223px;
  left: calc(50% - 250px);
  width: 128px;
  height: 128px;
  background: #FAB8C4;
}

@media only screen and (max-width: 1199px) {
  .entry__circle:nth-child(2) {
    left: calc(50% - 150px);
  }
}

@media only screen and (max-width: 1023px) {
  .entry__circle:nth-child(2) {
    top: 600px;
    left: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .entry__circle:nth-child(2) {
    top: auto;
    right: -64px;
    left: auto;
    bottom: -64px;
  }
}

.entry__circle:nth-child(3) {
  top: 68px;
  right: calc(50% - 158px);
  width: 32px;
  height: 32px;
  background: #FFDC60;
}

@media only screen and (max-width: 1023px) {
  .entry__circle:nth-child(3) {
    top: -10px;
  }
}

.entry__circle:nth-child(4) {
  right: calc(50% - 620px);
  bottom: 196px;
  width: 67px;
  height: 67px;
  background: #FAB8C4;
}

@media only screen and (max-width: 1023px) {
  .entry__circle:nth-child(4) {
    right: 120px;
    bottom: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .entry__circle:nth-child(4) {
    top: 150px;
    right: 22px;
    bottom: auto;
    width: 48px;
    height: 48px;
  }
}

.entry__circle:nth-child(5) {
  right: calc(50% - 448px);
  bottom: -107px;
  width: 215px;
  height: 215px;
  background: #FFDC60;
}

@media only screen and (max-width: 767px) {
  .entry__circle:nth-child(5) {
    left: -33px;
    right: auto;
    bottom: 85px;
    width: 67px;
    height: 67px;
  }
}

.entry__cube {
  top: 320px;
  right: calc(50% - 773px);
  z-index: -1;
  width: 140px;
}

@media only screen and (max-width: 1439px) {
  .entry__cube {
    right: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .entry__cube {
    width: 120px;
  }
}

.entry_variants .entry__form .entry__info {
  margin-bottom: 64px;
}

@media only screen and (max-width: 767px) {
  .entry_variants .entry__form .entry__info {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .entry_variants .entry__head {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .entry_variants .entry__cube {
    top: 92px;
    left: 50%;
    right: auto;
    transform: translateX(-45%);
  }
}

@media only screen and (max-width: 767px) {
  .entry_variants .entry__title {
    font-size: 32px;
  }
}

.entry_solo .entry__form .entry__info {
  margin-bottom: 48px;
}

@media only screen and (max-width: 767px) {
  .entry_solo .entry__form .entry__info {
    margin-bottom: 32px;
  }
}

.entry_solo .entry__form .entry__btn {
  min-width: 213px;
}

@media only screen and (max-width: 1023px) {
  .entry_solo .entry__form .entry__btn {
    min-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .entry_solo .entry__head {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .entry_solo .entry__cube {
    top: auto;
    bottom: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .entry_solo .entry__circle:nth-child(2) {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .entry_solo .entry__links .entry__link {
    color: #5956E9;
  }
}

.field__label {
  text-align: left;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #292930;
}

.field > .field__label {
  margin-bottom: 16px;
}

.field__line {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.field__link {
  font-size: 14px;
  line-height: 1;
  color: #5956E9;
  transition: opacity .25s;
}

.field__link:hover {
  opacity: .8;
}

.field__line .field__link {
  margin-left: auto;
}

.field__wrap {
  position: relative;
}

.field__input, .field__textarea {
  width: 100%;
  border: 1px solid #E3E6E9;
  border-radius: 16px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #292930;
  transition: all .25s;
}

.field__input::placeholder, .field__textarea::placeholder {
  color: #999FAE;
}

.field__input:focus, .field__textarea:focus {
  border-color: transparent;
  box-shadow: 0 10px 25px rgba(41, 41, 48, 0.1);
}

.field__input {
  height: 72px;
  padding: 0 24px 0 72px;
}

@media only screen and (max-width: 767px) {
  .field__input {
    padding-left: 58px;
  }
}

.field__textarea {
  height: 130px;
  padding: 21px 24px 16px 72px;
  resize: none;
}

@media only screen and (max-width: 767px) {
  .field__textarea {
    padding-left: 58px;
  }
}

.field__icon {
  position: absolute;
  top: 24px;
  left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .field__icon {
    left: 24px;
  }
}

.field__icon .field__pic {
  max-width: 24px;
}

.field_textrea {
  font-size: 0;
}

.popup {
  position: relative;
  max-width: 760px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .popup {
    max-width: 100%;
    min-height: 100vh;
    border-radius: 0;
  }
}

.popup__wrap {
  position: relative;
}

.popup__head {
  margin-bottom: 60px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .popup__head {
    display: none;
  }
}

.popup__logo {
  display: inline-block;
  font-size: 0;
}

.popup__logo .popup__pic {
  width: 145px;
}

.popup__body {
  padding: 64px 80px;
  background: #fff;
  border-radius: 40px;
}

@media only screen and (max-width: 767px) {
  .popup__body {
    padding: 56px 20px 30px;
    border-radius: 0;
  }
}

.popup__title {
  margin-bottom: 16px;
  text-align: center;
  font-size: 64px;
}

@media only screen and (max-width: 767px) {
  .popup__title {
    font-size: 38px;
  }
}

.popup__info {
  margin-bottom: 35px;
  text-align: center;
  color: #999FAE;
}

.popup__form {
  position: relative;
  z-index: 2;
  max-width: 387px;
  margin: 0 auto;
}

.popup__field:not(:last-child) {
  margin-bottom: 16px;
}

.popup__btn {
  height: 64px;
}

.popup__circle, .popup__preview {
  position: absolute;
  pointer-events: none;
  font-size: 0;
}

.popup__circle {
  border-radius: 50%;
}

.popup__circle:first-child {
  top: -14px;
  right: 221px;
  width: 32px;
  height: 32px;
  background: #FAB8C4;
}

@media only screen and (max-width: 767px) {
  .popup__circle:first-child {
    display: none;
  }
}

.popup__circle:first-child {
  top: 214px;
  left: -24px;
  width: 48px;
  height: 48px;
  background: #FFDC60;
}

@media only screen and (max-width: 767px) {
  .popup__circle:first-child {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .popup__preview {
    display: none;
  }
}

.popup__preview:first-child {
  top: 225px;
  right: -200px;
  width: 600px;
}

.popup__preview:nth-child(2) {
  left: -100px;
  bottom: 85px;
  width: 230px;
}

.popup__preview .popup__pic {
  width: 100%;
}

.popup .mfp-close {
  position: absolute;
  top: 140px;
  right: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  line-height: normal;
  opacity: 1;
  font-size: 0;
  background: #fff url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 14.2929C-0.0976311 14.6834 -0.0976311 15.3166 0.292893 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L7.99999 9.41423L14.2929 15.7071C14.6834 16.0977 15.3166 16.0977 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.4142 8.00001L15.7071 1.70711C16.0976 1.31658 16.0976 0.683417 15.7071 0.292893C15.3166 -0.0976312 14.6834 -0.0976308 14.2929 0.292893L7.99999 6.5858L1.70711 0.292922C1.31658 -0.0976023 0.683419 -0.0976023 0.292895 0.292922C-0.0976295 0.683446 -0.0976295 1.31661 0.292895 1.70714L6.58577 8.00001L0.292893 14.2929Z' fill='%23292930'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/12px auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: box-shadow .25s;
}

@media only screen and (max-width: 767px) {
  .popup .mfp-close {
    top: 16px;
    right: 16px;
  }
}

.popup .mfp-close:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.mfp-container {
  padding: 40px 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .mfp-container {
    padding: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .mfp-container {
    padding: 0;
  }
}

@media (max-width: 900px) {
  .mfp-container {
    padding: 40px;
  }
}

.mfp-bg {
  background: url("../img/bg-popup.png") no-repeat 50% 20%/cover;
}

.mfp-zoom-in .popup {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.2s ease-out;
}

.mfp-zoom-in.mfp-ready .popup {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 1;
}

.mfp-zoom-in.mfp-removing .popup {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

[data-aos="animation-scale-x-left"] {
  transform: translateX(-50px) scaleX(1.1);
  opacity: 0;
  transition-property: transform, opacity;
}

[data-aos="animation-scale-x-left"].aos-animate {
  transform: translateX(0) scaleX(1);
  opacity: 1;
}

[data-aos="animation-scale-x-right"] {
  transform: translateX(50px) scaleX(1.1);
  opacity: 0;
  transition-property: transform, opacity;
}

[data-aos="animation-scale-x-right"].aos-animate {
  transform: translateX(0) scaleX(1);
  opacity: 1;
}

[data-aos="animation-scale-y"] {
  transform: translateY(40px) scaleY(1.4);
  opacity: 0;
  transition-property: transform, opacity;
}

[data-aos="animation-scale-y"].aos-animate {
  transform: translateY(0) scaleY(1);
  opacity: 1;
}

[data-aos="animation-scale-x"] {
  transform: translateX(-40px) scaleX(1.1);
  opacity: 0;
  transition-property: transform, opacity;
}

[data-aos="animation-scale-x"].aos-animate {
  transform: translateX(0) scaleX(1);
  opacity: 1;
}

[data-aos="animation-translate-y"] {
  transform: translateY(40px);
  opacity: 0;
  transition-property: transform, opacity;
}

[data-aos="animation-translate-y"].aos-animate {
  transform: translateY(0);
  opacity: 1;
}
